
export const BFP_Message = ( gender,value ) => {

    console.log( gender )
    console.log( value )
    if (gender === "Male"){
        
        if (value < 13 )
        {
            return{
                'en' :  `We Cannot Find a message With This BFP Value, For More Info Please Contact ${<a href="https://api.whatsapp.com/send?phone=0019548019957"> Hello Health </a>}` ,
                'ar' : `لا يمكننا العثور على رسالة بقيمة BFP هذه ، لمزيد من المعلومات ، يرجى الاتصال بـ ${<a href="https://api.whatsapp.com/send؟phone=0019548019957"> Hello Health </a>}`
                
            }
        }
        else if ( value >= 13.1 && value <= 17 )
        {
            return{
                'en' : "The body fat percentage tells you how much of your weight is fat including essential fat and stored fat. Your body fat percentage is within the range of the healthy numbers, and you likely have a fit body. You are encouraged to maintain yourself within this range, the fitness range. To help you maintain your fitness level, we suggest you join RS challenges, Tone it Up program.",
                'ar' : 'تخبرك نسبة الدهون في الجسم بمقدار الدهون في وزنك بما في ذلك الدهون الأساسية والدهون المخزنة. تقع نسبة الدهون في جسمك ضمن نطاق الأرقام الصحية ، ومن المحتمل أن يكون لديك جسم لائق. نشجعك على الحفاظ على نفسك ضمن نطاق اللياقة البدنية هذا. لمساعدتك في الحفاظ على مستوى لياقتك ، نقترح عليك الانضمام إلى تحديات RS ، برنامج Tone it Up.'
            }

        }
        else if ( value >= 17.1 && value <= 24.9 )
        {
            return{
                'en' : "The body fat percentage tells you how much of your weight is fat including essential fat and stored fat. Your body fat percentage is at the average level, which is acceptable. But you are encouraged to improve your muscle mass by incorporating resistance exercise into your lifestyle twice per week. You are also advised to join our RS challenges, Size it Down program to help you increase your muscle mass and improve your fitness level.",
                'ar' : 'تخبرك نسبة الدهون في الجسم بمقدار الدهون في وزنك بما في ذلك الدهون الأساسية والدهون المخزنة. نسبة الدهون في جسمك عند مستوى متوسط ​​، وهو أمر مقبول. لكن يتم تشجيعك على تحسين كتلة عضلاتك من خلال دمج تمارين المقاومة في نمط حياتك مرتين في الأسبوع. يُنصح أيضًا بالانضمام إلى تحديات RS ، برنامج Size it Down لمساعدتك على زيادة كتلة عضلاتك وتحسين مستوى لياقتك.'
            }
        }
        else if ( value >= 25){
            return {
                'en' : "The body fat percentage tells you how much of your weight is fat including essential fat and stored fat. Your numbers indicate that you have an excessive amount of body fat. You are encouraged to improve your muscle mass by incorporating resistance exercise into your lifestyle twice per week. You are advised to join our RS challenges, Size it Down program to help you increase your muscle mass and improve your fitness level. Consult your physician and a Registered Dietitian to help you reduce body fat percentage. Check out Weigh to Go By The Healthy numbers program, it would be the right program for you.",
                'ar' : 'تخبرك نسبة الدهون في الجسم بمقدار الدهون في وزنك بما في ذلك الدهون الأساسية والدهون المخزنة. تشير الأرقام الخاصة بك إلى أن لديك كمية زائدة من الدهون في الجسم. نشجعك على تحسين كتلة عضلاتك من خلال دمج تمارين المقاومة في نمط حياتك مرتين في الأسبوع. يُنصح بالانضمام إلى تحديات RS ، برنامج Size it Down لمساعدتك على زيادة كتلة عضلاتك وتحسين مستوى لياقتك. استشر طبيبك وأخصائي التغذية المعتمد لمساعدتك على تقليل نسبة الدهون في الجسم. تحقق من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else
        {
            return{
                'en' :  `We Cannot Find a message With This BFP Value, For More Info Please Contact ${<a href="https://api.whatsapp.com/send?phone=0019548019957"> Hello Health </a>}` ,
                'ar' : `لا يمكننا العثور على رسالة بقيمة BFP هذه ، لمزيد من المعلومات ، يرجى الاتصال بـ ${<a href="https://api.whatsapp.com/send؟phone=0019548019957"> Hello Health </a>}`
                
            }
        }
    }
    else {
        if (value < 21) {
            return{
                'en' :  `We Cannot Find a message With This BFP Value, For More Info Please Contact ${<a href="https://api.whatsapp.com/send?phone=0019548019957"> Hello Health </a>}` ,
                'ar' : `لا يمكننا العثور على رسالة بقيمة BFP هذه ، لمزيد من المعلومات ، يرجى الاتصال بـ ${<a href="https://api.whatsapp.com/send؟phone=0019548019957"> Hello Health </a>}`
                
            }
        }
        else if ( value >=21.1 && value <= 24.9)
        {
            return{
                'en' :  "The body fat percentage tells you how much of your weight is fat including essential fat and stored fat. Your body fat percentage is within the range of the healthy numbers, and you likely have a fit body. You are encouraged to maintain yourself within this range, the fitness range. To help you maintain your fitness level, we suggest you join RS challenges, Tone it Up program. ",
                'ar' : 'تخبرك نسبة الدهون في الجسم بمقدار الدهون في وزنك بما في ذلك الدهون الأساسية والدهون المخزنة. تقع نسبة الدهون في جسمك ضمن نطاق الأرقام الصحية ، ومن المحتمل أن يكون لديك جسم لائق. نشجعك على الحفاظ على نفسك ضمن نطاق اللياقة البدنية هذا. لمساعدتك في الحفاظ على مستوى لياقتك ، نقترح عليك الانضمام إلى تحديات RS ، برنامج Tone it Up.'
            }
        }
        else if ( value >= 25 && value <= 31.9)
        {
            return{
                'en' :  "The body fat percentage tells you how much of your weight is fat including essential fat and stored fat. Your body fat percentage is at the average level, which is acceptable. But you are encouraged to improve your muscle mass by incorporating resistance exercise into your lifestyle twice per week. You are also advised to join our RS challenges, Size it Down program to help you increase your muscle mass and improve your fitness level.",
                'ar' : 'تخبرك نسبة الدهون في الجسم بمقدار الدهون في وزنك بما في ذلك الدهون الأساسية والدهون المخزنة. نسبة الدهون في جسمك عند مستوى متوسط ​​، وهو أمر مقبول. لكن يتم تشجيعك على تحسين كتلة عضلاتك من خلال دمج تمارين المقاومة في نمط حياتك مرتين في الأسبوع. يُنصح أيضًا بالانضمام إلى تحديات RS ، برنامج Size it Down لمساعدتك على زيادة كتلة عضلاتك وتحسين مستوى لياقتك.'
            }
        }
        else if ( value >= 32){
            return{
                'en' :  "The body fat percentage tells you how much of your weight is fat including essential fat and stored fat. Your numbers indicate that you have an excessive amount of body fat. You are encouraged to improve your muscle mass by incorporating resistance exercise into your lifestyle twice per week. You are advised to join our RS challenges, Size it Down program to help you increase your muscle mass and improve your fitness level. Consult your physician and a Registered Dietitian to help you reduce body fat percentage. Check out Weigh to Go By The Healthy numbers program, it would be the right program for you.",
                'ar' : 'تخبرك نسبة الدهون في الجسم بمقدار الدهون في وزنك بما في ذلك الدهون الأساسية والدهون المخزنة. تشير الأرقام الخاصة بك إلى أن لديك كمية زائدة من الدهون في الجسم. نشجعك على تحسين كتلة عضلاتك من خلال دمج تمارين المقاومة في نمط حياتك مرتين في الأسبوع. يُنصح بالانضمام إلى تحديات RS ، برنامج Size it Down لمساعدتك على زيادة كتلة عضلاتك وتحسين مستوى لياقتك. استشر طبيبك وأخصائي التغذية المعتمد لمساعدتك على تقليل نسبة الدهون في الجسم. تحقق من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else
        {
            return{
                'en' :  `We Cannot Find a message With This BFP Value, For More Info Please Contact ${<a href="https://api.whatsapp.com/send?phone=0019548019957"> Hello Health </a>}` ,
                'ar' : `لا يمكننا العثور على رسالة بقيمة BFP هذه ، لمزيد من المعلومات ، يرجى الاتصال بـ ${<a href="https://api.whatsapp.com/send؟phone=0019548019957"> Hello Health </a>}`
                
            }
        }
    }
   
}