
export const BMI_Message = ( value ) => {
    if ( value <= 18.4 ){
        return {

            "en" :  "You are underweight! Your body weight is below the range of the healthy numbers. This may put you at a nutritional risk. You need to gain some weight to reach the healthy numbers. Consult a registered dietitian to guide you through that. Check out our A Basket of Cherries program, it would be the right program for you!" ,
            "ar" : "أنت تعاني من نقص الوزن! وزن جسمك أقل من نطاق الأرقام الصحية. هذا قد يعرضك لمخاطر غذائية. تحتاج إلى اكتساب بعض الوزن للوصول إلى الأرقام الصحية. استشر اختصاصي تغذية مسجل لإرشادك خلال ذلك. تحقق من برنامج A Basket of Cherries الخاص بنا ، سيكون البرنامج المناسب لك!"

            }
    }
    else if ( value >= 18.5 && value <= 24.9 )
    {
        return {
            "en" : "Your body weight is within the range of the healthy numbers. Check out the results of the other parameters.",
            "ar" : "وزن جسمك ضمن نطاق الأرقام الصحية. تحقق من نتائج المعلمات الأخرى."
        } 
    }
    else if ( value >= 25 &&  value <= 29.9 )
    {
        return{ 
            "en" :  "You are classified as an overweight person. Your body weight is above the range of the healthy numbers. You are not that far from the healthy numbers. You are advised to make some healthier behaviors. Consult a registered dietitian to guide you through that. Check out our A Basket of Cherries program, it would be the right program for you!",
            "ar" : "تم تصنيفك على أنك شخص يعاني من زيادة الوزن. وزن جسمك أعلى من نطاق الأرقام الصحية. أنت لست بعيدًا عن الأرقام الصحية. يُنصح بالقيام ببعض السلوكيات الصحية. استشر اختصاصي تغذية مسجل لإرشادك خلال ذلك. تحقق من برنامج A Basket of Cherries الخاص بنا ، سيكون البرنامج المناسب لك!"
            }
    }
    else if ( value >= 30 && value <= 34.9 )
    {
        return{
            "en" : "Your body weight is significantly above the range of the healthy numbers. You are at the level 1 of obesity. This may increase your health risk. You are advised to consult a registered dietitian to help you bring your body weight to its healthy numbers. Check out our Weigh to go by The Healthy Numbers program, it would be the right program for you.",
            "ar" : "وزن جسمك أعلى بكثير من نطاق الأرقام الصحية. أنت في المستوى 1 من السمنة. هذا قد يزيد من المخاطر الصحية الخاصة بك. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك على رفع وزن جسمك إلى مستوياته الصحية. تحقق من برنامج Weigh الخاص بنا للذهاب من خلال برنامج The Healthy Numbers ، سيكون البرنامج المناسب لك."
        }
    }
    else if (value >= 35 && value <= 39.9)
    {
        return{
            "en" :  "Your body weight is significantly above the range of the healthy numbers. You are at the level 2 of obesity. This may increase your health risk. You are advised to consult a registered dietitian to help you bring your body weight to its healthy numbers. Check out our Weigh to go by The Healthy Numbers program, it would be the right program for you.",
            "ar" : "وزن جسمك أعلى بكثير من نطاق الأرقام الصحية. أنت في المستوى 2 من السمنة. هذا قد يزيد من المخاطر الصحية الخاصة بك. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك على رفع وزن جسمك إلى مستوياته الصحية. تحقق من برنامج Weigh الخاص بنا للذهاب من خلال برنامج The Healthy Numbers ، سيكون البرنامج المناسب لك."
        }
    }
    else 
    {
        return{
            "en" :  "Your body weight is significantly above the range of the healthy numbers. You are at the level of extreme obesity. This may increase your health risk. You are advised to consult a registered dietitian to help you bring your body weight to its healthy numbers. Check out our Weigh to go by The Healthy Numbers program, it would be the right program for you.",
            "ar" : "وزن جسمك أعلى بكثير من نطاق الأرقام الصحية. أنت في مستوى السمنة المفرطة. هذا قد يزيد من المخاطر الصحية الخاصة بك. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك على رفع وزن جسمك إلى مستوياته الصحية. تحقق من برنامج Weigh الخاص بنا للذهاب من خلال برنامج The Healthy Numbers ، سيكون البرنامج المناسب لك."
        }
    }
}