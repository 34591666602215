import React, { useContext } from 'react'
import  { SimpleContext } from '../Context/ContextData'

function Read_More() {

    const { Language } = useContext(SimpleContext)
  return (
    <div className="main">
    {/*page header section start*/}
   
    <section className="feature-tab-section ptb-100">
      <div className="container">
        <div className="row">
                Read More....
          {/* <div className="col-12 order-xl-first">

            <h4>  { Language === "en" ? 'RS Challenge' : 'تحدي رشا'} </h4>
            <p>{ Language === "en" ? 'Personalize-Compete-Achieve' : ''}</p>

            <h4>  { Language === "en" ? 'What is RS challenge? ' : ''} </h4>
            <p>{ Language === "en" ? 'It is a science-based challenge welcoming everyone with social media accounts including YOU to join. The desired outcome is to incorporate physical activity into your daily routine in fun and COMPETETIVE ways.  The challenge is a monthly based; however, it does not always follow the calendar. Sometimes, it is planned to be associated with special holidays and occasions such as Christmas and Ramadan.' : ''}</p>

            <h4>  { Language === "en" ? 'What is the concept of this challenge?' : ''} </h4>
            <p>{ Language === "en" ? 'First, it is yourself, your body needs, and your health goals. Then it’s the group competition.Scientifically, different people have different fitness levels and health goals. Many studies find that different numbers of additional steps to your daily routine make you reach different health goals! RS challenge relies on these scientific evidences. ' : ''}</p>
            <p>{ Language === "en" ? 'I PERSONALIZE it for YOU! The starting point is health goal. Your fitness level is used to determine the number of steps you need to add to your daily routine to ACHIEVE your health goal. Once we set our different #NumberOfSteps, we start to COMPETE as a team to reach our personal health goals on a daily basis for 30 days straight. Because people have different health goals, RS challenge is structured into three sub-programs according to the findings of different studies, TONE It UP, SIZE it DOWN, and MIX it UP.' : ''}</p>

            <h4>{ Language === "en" ? 'What is the right sub-program for me?  ' : ''}</h4>
            <p>{ Language === "en" ? 'If your health goal is to improve your fitness level, TONE it UP is the right program to join. The TONE it UP steps are programmed to improve your fitness level, increase your muscle strength, and improve your overall balance. ' : ''}</p>
            <p>{ Language === "en" ? 'If you want to improve your body composition, and you desire to replace some fat with muscle while maintaining your weight, join the team through Size It Down program. ' : ''}</p>
            <p>{ Language === "en" ? 'Mix it up program is “still under development”. This is a promising program that mixes different strategies together to achieve different goals. You Improve your fitness level, change your body composition, improve your dietary habits, and get closer to your body’s healthy numbers. You will be provided with dietary guidance tailored for you, general guidance to navigate your options everyday, and weekly feedback on your progress through emails. You get an access to different log sheets allowing me to follow you up on a weekly basis. Recommended for people with busy schedules, weight loss and/or diabetes. ' : ''}</p>
            
            <h4>{ Language === "en" ? 'A brief' : ''}</h4>
            <p>{ Language === "en" ? 'RS challenge was initiated in response to COVID 19 quarantine. As COVID 19 pandemic started, the focus was on respiratory health. Exercise plays a preventive role and improves respiratory system and immune system.  The first post was a story on Instagram to invite people to compete with me over steps. In 3 weeks, we became a team competing on a daily basis. Afterward, I decided to know what the health science has for the new concept of STEP COUNTING. At this point, the findings of many studies had amazed me. Let me tell you that the science says:” different numbers of additional steps to your daily routine make you reach different health goals!” Is not it amazing? That is why I decided to apply different findings into our RS challenge.  Today, RS challenge is science-based health promoting program, and every step challenger has their own health goal. The individual health goal is translated into number of daily steps. We, happy team, compete to achieve our personalized goals! ' : ''}</p>
            <p>{ Language === "en" ? 'If you are interested in improving your physical activity, fitness, or health scores, join our happy team and be a step challenger!' : '' }</p>

            <h4>Racha </h4>

          </div> */}
        </div>
      </div>

    </section>

  </div>
  )
}

export default Read_More