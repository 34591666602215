import { createContext, useEffect, useState } from "react";
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../Constants/baseURL";
import { Show_Toast } from "../Constants/Toast";





export const SimpleContext = createContext()

const ContextData = ({children}) => {

    let navigate = useNavigate()


    const [Language, setLanguage] = useState(()=>localStorage.getItem('hl_lng') ? localStorage.getItem('hl_lng') : 'en')

    const [authtoken, setauthtoken] = useState(()=>localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [user, setUser] = useState(()=>localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    const [login_modal, setlogin_modal] = useState(false)

    const [assesment_modal, setassesment_modal] = useState(false)
    const [validated, setValidated] = useState({
        login : false , 
        register : false ,
        assesment : false,
        metricbmi : false,
        standared : false ,
        calorie : false,
        contactForm : false,
        validatechallenge : false,
        validateprogramme : false,
        addsteps : false,
        updatesteps : false,
        forgotpasswordmodal : false,
        resetpass : false,
    })

    
    const [calorie_validate, setcalorie_validate] = useState(false)

    const Logout = () => {
        setauthtoken(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        localStorage.removeItem('user')
        localStorage.removeItem('hl_lng')
        Show_Toast("Succesfully Logged Out",true)
        return navigate('/')
    }

    useEffect(() => {
        
        let interval = setInterval (() => {
            if (authtoken) {
                UpdateToken()
            }

        },  60 * 60 * 1000 );
        return ()=> clearInterval(interval)

    }, [authtoken])



    
    const UpdateToken = () => {
        var data = {
            'refresh' : authtoken.refresh
        }
        axios.post(`${BaseURL}/user_details/get_refresh_token/`,data)
        .then((res) => {
            console.log("Update Token")
            setauthtoken(res.data)
            setUser(jwt_decode(res.data.access))
            localStorage.setItem('authTokens',JSON.stringify(res.data))
        })
        .catch((err) => {
            Logout()
        })
    }


    // const API_Call = async (method,end_point,data) => {

    //     let response = ""
    //     if (method === "get")
    //     {
    //         return (
    //             await axios.get(`${BaseURL}${end_point}`,{
    //                 headers :
    //                 {
    //                     Authorization :  `${ authtoken ? `Bearer ${authtoken.access}` : authtoken}`
    //                 },
    //                 params : data
    //             })
    //             .then((res)=>  {
         
    //                 return res.data
    //             })
    //             .catch((error) => {
                   
    //                 return error.response.data
    //             })
    //         )
    //     }
    //     else if ( method === "post" )
    //     {
    //         return (
           
    //             await axios.post(`${BaseURL}${end_point}`,data,{
    //                 headers :
    //                 {
    //                     Authorization :  `${ authtoken ? `Bearer ${authtoken.access}` : authtoken}`
    //                 }
    //             })
                
    //             .then((res)=>  {
    //                 console.log("okokokokokokkokokokokokkokkok")
    //                 console.log(res.data)
    //                 return res.data
    //             })
    //             .catch((error) => {
    //                 console.log("ERORROROROOROROROROOROROROROROROROROROR")
    //                 console.log(error)
    //                 var result = "ok"
    //                 return result
    //             })
    //         )
    //     }
    // }


    const API_Call = async (method,end_point,data) => {
        let response = ""
        try{
           
            if (method === "get"){
                response = await axios.get(`${BaseURL}${end_point}` , {
                    headers : {
                        Authorization :  `${ authtoken ? `Bearer ${authtoken.access}` : authtoken}`
                    },
                    params : data
                })   
            }

            else if (method === "post"){
                
                response = await axios.post(`${BaseURL}${end_point}`,data,{
                    headers :
                    {
                        Authorization :  `${ authtoken ? `Bearer ${authtoken.access}` : authtoken}`
                    }
                })
          
            }

            console.log(response)
            
            return response
        }
        catch (error) {
           
            if (error.response.data.username){
                response  = {
                    'status' : 500,
                    'message' : "The Username Already Resgistered"
                }
            }
            else
            {
                response  = {
                    'status' : error.response.status,
                    'message' : error.response.data?.message ?? error.response.data?.detail ?? "Internal Server Error"
                }
            }
        
            return response
        }
    }



    const Check_Validation = (event , fun_name ) => {
  
        const form = event.currentTarget;
        event.preventDefault();
        
        if (event.target.id === "loginmodal")
        {
            setValidated({login:true})
        }
        else if ( event.target.id === "registermodal")
        {
            setValidated({register : true })
        }
        else if (event.target.id === "assesment")
        {
            setValidated({ assesment : true })
        }
        else if (event.target.id === "metricbmi")
        {
            setValidated({ metricbmi : true })
        }
        else if (event.target.id === "standared")
        {
            setValidated({ standared : true })
        }
        else if (event.target.id === "calorie")
        {
            setValidated({ calorie : true })
        }
        else if (event.target.id === "contactForm")
        {
            setValidated({ contactForm : true })
        }
        else if (event.target.id === "validatechallenge")
        {
            setValidated({ validatechallenge : true })
        }
        else if (event.target.id === "validateprogramme")
        {
            setValidated({ validateprogramme : true })
        }
        else if (event.target.id === "addsteps")
        {
            setValidated({ addsteps : true })
        }
        else if (event.target.id === "updatesteps")
        {
            setValidated({ updatesteps : true })
        }
        else if (event.target.id === "forgotpasswordmodal")
        {
            setValidated({ forgotpasswordmodal : true })
        }
        else if (event.target.id === "resetpass")
        {
            setValidated({ resetpass : true })
        }
        

        // setValidated(true)

        if (form.checkValidity() === false) {
          event.stopPropagation();
          return false     
        }
        else
        {   
            fun_name()
            return true
        }
      };





    return <SimpleContext.Provider value={{ calorie_validate,setcalorie_validate,Logout,setauthtoken , setLanguage ,setUser , user , login_modal , Check_Validation , setlogin_modal , validated , setValidated , API_Call , Language , authtoken ,
        assesment_modal , setassesment_modal }}>{children}</SimpleContext.Provider>
}

export default ContextData