import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SimpleContext } from "../Context/ContextData";

function Calculator() {

  let navigate =  useNavigate()

  const {
    login_modal,
    setlogin_modal,
    validated,
    setValidated,
    Check_Validation,
    API_Call,
    setUser,
    setauthtoken,
    Language,
    calorie_validate,
    setcalorie_validate
  } = useContext(SimpleContext);

  const [bmi_type, setbmi_type] = useState("metric")


  const [bmi_metric_height, setbmi_metric_height] = useState("")
  const [bmi_metric_weight, setbmi_metric_weight] = useState("")

  const [bmi_std_ft, setbmi_std_ft] = useState("")
  const [bmi_std_inch, setbmi_std_inch] = useState("")
  const [bmi_std_weight, setbmi_std_weight] = useState("")

  const [calorie_gender, setcalorie_gender] = useState("male")
  const [calorie_activity, setcalorie_activity] = useState("")
  const [calorie_height, setcalorie_height] = useState("")
  const [calorie_weight, setcalorie_weight] = useState("")
  const [calorie_age, setcalorie_age] = useState("")

  const [bmi_result, setbmi_result] = useState(null)
  const [calorie_result, setcalorie_result] = useState(null)

  const Resetmetric = (e) => {
    e.preventDefault()
    setbmi_std_ft("")
    setbmi_std_inch("")
    setbmi_std_weight("")
    setbmi_result(null)
  }

  const ResetStandared = (e) => {
    e.preventDefault()
    setbmi_metric_height("")
    setbmi_metric_weight("")
    setbmi_result(null)
  }

  const ResetCalorie = (e) => {
    e.preventDefault()
    setcalorie_activity("")
    setcalorie_height("")
    setcalorie_weight("")
    setcalorie_age("")
    setcalorie_result(null)
  }

  const Calculate_BMI = () => {
    if (bmi_type === "metric"){

        const calculation = ( bmi_metric_weight / (bmi_metric_height * bmi_metric_height ))  
        var result = calculation.toFixed(2)
        var category = ""
        if (result < 18)
        {
          category = "Under Weight"
        }
        else if (result >= 18 && result < 24.9 ){
          category = "Normal Weight"
        }
        else if ( result >= 25 && result < 29.9){
          category = "OverWeight"
        }
        else if ( result > 30) {
          category = "Obesity"
        }
        else {
          category = "Invalid Category"
        }
        setbmi_result({...bmi_result , value : result , category : category })
        setValidated({ metricbmi : false })
        // setbmi_result(parseFloat((result).toFixed(2)))
        
    }
    else
    {
          const height_in_inch = (parseFloat(bmi_std_ft * 12) + parseFloat(bmi_std_inch) )
    
          console.log(( bmi_std_weight / (height_in_inch * height_in_inch)))
  
          const calculation = (730 * ( bmi_std_weight / ( height_in_inch * height_in_inch )))
          var result = calculation.toFixed(2)
          var category = ""
          if (result < 18)
          {
            category = "Under Weight"
          }
          else if (result >= 18 && result < 24.9 ){
            category = "Normal Weight"
          }
          else if ( result >= 25 && result < 29.9){
            category = "OverWeight"
          }
          else if ( result > 30) {
            category = "Obesity"
          }
          else {
            category = "Invalid Category"
          }
          setbmi_result({...bmi_result , value : result , category : category })
          setValidated({standared : false })
    }
  }

  const Calculate_Calorie = () => {
    console.log("ok")

    if (calorie_gender === "Male")
      {
  
        var physical_activity_count = ""
        if (calorie_activity === "Sedentary")
        {
          physical_activity_count = 1.0
        }
        else if (calorie_activity === "Low active")
        {
          physical_activity_count = 1.11
        }
        else if (calorie_activity === "Active")
        {
          physical_activity_count = 1.25
        }
        else if (calorie_activity === "Very Active")
        {
          physical_activity_count = 1.48
        }
        else{
          console.log("False")
        }
  
        const Result = ( (662- (9.53 * calorie_age)) + physical_activity_count * ( (15.91 * calorie_weight) + (539.6 * calorie_height)))
        setcalorie_result(Math.round(Result))
        setValidated({calorie : false })
      
      }
      else{
        var physical_activity_count = ""
        if (calorie_activity === "Sedentary")
        {
          physical_activity_count = 1.0
        }
        else if (calorie_activity === "Low active")
        {
          physical_activity_count = 1.12
        }
        else if (calorie_activity === "Active")
        {
          physical_activity_count = 1.27
        }
        else if (calorie_activity === "Very Active")
        {
          physical_activity_count = 1.45
        }
        else{
          console.log("False")
        }
  
        const Result = ( (354- (6.91  * calorie_age)) + physical_activity_count * ( (9.36  * calorie_weight) + (726 * calorie_height)))
        console.log(Result)
        setcalorie_result(Math.round(Result))
        setValidated({calorie : false })
        
  
      }


  }


  return (
    <div className="main">
      <section className="our-services ptb-100 gray-light-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="single-service-plane rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
                <div className="service-plane-content">
                  <h3 className="h5"> { Language === "en" ? 'BMI Calculator' : 'مؤشر كتلة الجسم'}</h3>
                  <div className="row">
                    <div className="col-12 col-md-6 mt-3">
                      <ul
                        className="nav nav-tabs feature-tab feature-new-tab mb-3 border-bottom-0"
                        data-tabs="tabs"
                      >
                        <li className="nav-item" onClick={()=>setbmi_type("metric")}>
                          <a
                            className="nav-link d-flex align-items-center active"
                            href="#feature-tab-1"
                            data-toggle="tab"
                          >
                            <h6 className="mb-0">{ Language === "en" ? "Metric" : 'متر/كغ'}</h6>
                          </a>
                        </li>
                        <li className="nav-item" onClick={()=>setbmi_type("standared")}>
                          <a
                            className="nav-link d-flex align-items-center"
                            href="#feature-tab-2"
                            data-toggle="tab"
                          >
                            <h6 className="mb-0">{ Language === "en" ? 'Standared' : 'بوصة/رطل'}</h6>
                          </a>
                        </li>
                      </ul>

                      {
                        bmi_type === "metric" ? <>
                          <Form className="login-signup-form"  
                          noValidate validated={validated.metricbmi} 
                          id = "metricbmi"
                          onSubmit={(event)=>Check_Validation(event,Calculate_BMI)}>
                          <div className="form-group">
                          <label className="pb-1">{ Language === "en" ? 'Height'  : 'الطول'}</label>
                          <div class="input-group mb-2 mr-sm-2">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="inlineFormInputGroupUsername2"
                                  value={ bmi_metric_height }
                                  onChange = {(e) => setbmi_metric_height(e.target.value)}
                                  required
                               
                                />
                                <div class="input-group-append">
                                  <div class="input-group-text">{ Language === "en" ? 'm' : 'متر'}</div>
                                </div>

                                <Form.Control.Feedback type="invalid">
                                  Provide a Valid Height
                                </Form.Control.Feedback>

                              </div>


                          
                          </div>
                          <div className="form-group">
                          <label className="pb-1">{ Language === "en" ? 'Weight' : 'الوزن'}</label>
                          <div class="input-group mb-2 mr-sm-2">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="inlineFormInputGroupUsername2"
                                  value = { bmi_metric_weight}
                                  onChange = {(e)=>setbmi_metric_weight(e.target.value)}
                                  required
                               
                                />
                                <div class="input-group-append">
                                  <div class="input-group-text">{ Language === "en" ? 'Kg' : 'كغ'}</div>
                                </div>

                                <Form.Control.Feedback type="invalid">
                                  Provide a Valid Weight
                                </Form.Control.Feedback>
                              </div>


                          
                          </div>
                            <div>
                              <button className="btn primary-solid-btn border-radius mr-2" onClick={(e)=>Resetmetric(e)}>{ Language === "en" ? 'Reset' : 'إعادة'}</button>
                              <button className="btn primary-solid-btn border-radius">{ Language === "en" ? 'Calculate' : 'احتساب'}</button>
                            </div>
                          
                          </Form>
                        </> :
                        <Form className="login-signup-form" 
                        noValidate validated={validated.standared} 
                        id  = "standared"
                        onSubmit={(event)=>Check_Validation(event,Calculate_BMI)}>
                        <div className="form-group">
                        <label className="pb-1">{ Language === "en" ? 'Height'  : 'الطول'}</label>
                        <div class="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                class="form-control"
                                id="inlineFormInputGroupUsername2"
                                value={bmi_std_ft} 
                                onChange={(e)=>setbmi_std_ft(e.target.value)}
                                required
                             
                              />
                              <div class="input-group-append">
                                <div class="input-group-text">{ Language === "en" ? 'ft' : 'قدم'}</div>
                              </div>

                              <Form.Control.Feedback type="invalid">
                                  Provide a Valid Height in Ft
                                </Form.Control.Feedback>


                            </div>

                            <div class="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                class="form-control"
                                id="inlineFormInputGroupUsername2"
                                value={bmi_std_inch} 
                                onChange={(e)=>setbmi_std_inch(e.target.value)}
                                required
                             
                              />
                              <div class="input-group-append">
                                <div class="input-group-text">{ Language === "en" ? 'in' : 'بوصة'}</div>
                              </div>

                              <Form.Control.Feedback type="invalid">
                                  Provide a Valid Height in Inch
                                </Form.Control.Feedback>
                            </div>


                        
                        </div>
                        <div className="form-group">
                        <label className="pb-1">{ Language === "en" ? 'Weight' : 'الوزن'}</label>
                        <div class="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                class="form-control"
                                id="inlineFormInputGroupUsername2"
                                value={bmi_std_weight} 
                                onChange={(e)=>setbmi_std_weight(e.target.value)}
                                required
                             
                              />
                              <div class="input-group-append">
                                <div class="input-group-text">lbs</div>
                              </div>

                              <Form.Control.Feedback type="invalid">
                                  Provide a Valid Weight
                                </Form.Control.Feedback>
                            </div>

                        </div>
                        <div>
                              <button className="btn primary-solid-btn border-radius mr-2" onClick={(e)=>ResetStandared(e)}>{ Language === "en" ? 'Reset' : 'إعادة'}</button>
                              <button className="btn primary-solid-btn border-radius">{ Language === "en" ? 'Calculate' : 'احتساب'}</button>
                            </div>
                        </Form>
                      }
                    </div>

                    <div className="col-md-6 col-12 mt-4" style={{ backgroundColor:"lightgray", borderRadius:"10px"}}>
                      <h5 style={{ padding:"5px"}}>{ Language === "en" ? 'Result' : 'النتيجة' }</h5>
                      {
                        bmi_result === null ? '' :
                        <div>
                          <b> BMI Value : { bmi_result.value } </b> <br/>
                          <b> BMI Categeory : { bmi_result.category } </b><br/>
                          <b> Note  : Obesity classification according to National Institute of Health (NIH)</b>
                        </div>
                      }
                     
                    </div>


                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="single-service-plane rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
              
                <div className="service-plane-content">
                  <h3 className="h5">{ Language === "en" ? 'Calorie Needs' : 'السعرات الحرارية'}</h3>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <ul
                        className="nav nav-tabs feature-tab feature-new-tab mb-3 border-bottom-0 mt-2"
                        data-tabs="tabs"
                      >
                        <li className="nav-item" onClick={()=>setcalorie_gender("male")}>
                          <a
                            className="nav-link d-flex align-items-center active"
                            href="#feature-tab-1"
                            data-toggle="tab"
                          >
                            <h6 className="mb-0">{ Language === "en" ? 'Male' : 'ذكر'}</h6>
                          </a>
                        </li>
                        <li className="nav-item" onClick={()=>setcalorie_gender("female")}>
                          <a
                            className="nav-link d-flex align-items-center"
                            href="#feature-tab-2"
                            data-toggle="tab"
                          >
                            <h6 className="mb-0">{ Language === "en" ? 'Female' : 'انثى'}</h6>
                          </a>
                        </li>
                      </ul>

                      <Form className="calorie_needs mt-4" 
                      noValidate validated={validated.calorie}
                      id  = "calorie"
                      onSubmit={(event)=>Check_Validation(event,Calculate_Calorie)}>
                        <div className="form-group">
                        <label className="pb-1">{ Language === "en" ? 'Physical Activity Level' : 'ستوى النشاط البدني' }</label>
                        <select className="form-control" required value={calorie_activity} onChange={(e)=>setcalorie_activity(e.target.value)}>
                          <option value="" defaultChecked > --{ Language === "en" ? 'Choose Activity Level' : 'اختر مستوى النشاط'} --</option>
                          <option value="Sedentary">Sedentary</option>
                          <option value="Low active">Low Active</option>
                          <option value="Active">Active</option>
                          <option value="Very Active">Very Active</option>
                        </select>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                          <div className="form-group">
                        <label className="pb-1">{ Language === "en" ? 'Height'  : 'الطول'}</label>
                        <div class="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                class="form-control"
                                id="inlineFormInputGroupUsername2"
                                value={calorie_height} 
                                onChange={(e)=>setcalorie_height(e.target.value)}
                                required
                             
                              />
                              <div class="input-group-append">
                                <div class="input-group-text">{ Language === "en" ? 'm' : 'متر'}</div>
                              </div>
                            </div>


                        
                        </div>
                          </div>
                          <div className="col-12 col-md-6">
                          <div className="form-group">
                        <label className="pb-1">{ Language === "en" ? 'Weight' : 'الوزن'}</label>
                        <div class="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                class="form-control"
                                id="inlineFormInputGroupUsername2"
                                value={calorie_weight} 
                                onChange={(e)=>setcalorie_weight(e.target.value)}
                                required
                             
                              />
                              <div class="input-group-append">
                                <div class="input-group-text">{ Language === "en" ? 'Kg' : 'كغ'}</div>
                              </div>
                            </div>


                        
                        </div>
                          </div>  
                        </div>
                      
                       

                        <div className="form-group">
                        <label className="pb-1">{ Language === "en" ? 'Age' : 'العمر'}</label>
                      
                              <input
                                type="text"
                                class="form-control"
                                id="inlineFormInputGroupUsername2"
                                value={calorie_age} 
                                onChange={(e)=>setcalorie_age(e.target.value)} 
                                required
                             
                              />
                              
                  


                        
                        </div>


                        <div>
                              <button className="btn primary-solid-btn border-radius mr-2" onClick={(e)=>ResetCalorie(e)}>{ Language === "en" ? 'Reset' : 'إعادة'}</button>
                              <button className="btn primary-solid-btn border-radius">{ Language === "en" ? 'Calculate' : 'احتساب'}</button>
                            </div>
                        </Form>



                    </div>

                    <div className="col-md-6 col-12 mt-4" style={{ backgroundColor:"lightgray", borderRadius:"10px"}}>
                      <h5 style={{ padding:"5px"}}>{ Language === "en" ? 'Result' : 'النتيجة' }</h5>
                        {
                          calorie_result === null ? '' : 
                          <div>
                          <b> Calorie Needs : { calorie_result }  </b> <br/>
                          <b> Note: This is your estimated energy requirement as per Dietary Reference Intakes  </b><br/>
                          <b> Note: If your gender is not listed among the options OR you are below 18 years old, reach out to me to help you determine your needs. <a href={ undefined } style={{ color:"blue" , cursor:"pointer" }} onClick={()=>{ return navigate('/Contact')}}> Contact Me </a></b>
                        </div>
                      
                        }
                      
                    </div>
                    
                   
                  </div>
                 
                </div>
            
              </div>
            </div>

           
          </div>
        </div>
      </section>
    </div>
  );
}

export default Calculator;
