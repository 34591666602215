import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SimpleContext } from '../Context/ContextData'
import '../Css/Success.css'

function Main_Success() {

	const { Language } = useContext(SimpleContext)

    let navigate = useNavigate()


    const Back_To_Home = () => {
        return navigate("/")
    }


  return (
    <div>
        <div id="myModal" class="modal fade show" style={{ display:"block",backgroundColor:"#eeeef5"}}>
	<div class="modal-dialog modal-confirm" style={{ margin:"80px auto"}}>
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
                    <li className="list-inline-item"><span className="fas fa-solid fa-shield-check" style={{ fontSize:"50px",marginTop:"-10px"}} /></li>
				</div>				
				<h4 class="modal-title w-100">{ Language === "en" ? 'Congratulations !' : 'تهانينا !'}</h4>	
			</div>
			<div class="modal-body">
				<p class="text-center">
					<strong> { Language === "en" ? <> You Are Succesfully Enrolled <br/> Thanks To Join Us </>  : <> أنت مسجل بنجاح <br/> شكرا للانضمام إلينا </> } </strong>
				</p>
			</div>
				<button class="btn btn-success btn-block" onClick={()=>Back_To_Home()} >{ Language === "en" ? 'Back To Home' : 'العودة إلى المنزل'}</button>

			{/* <div class="modal-footer">
                
				<button class="btn btn-success btn-block"  onClick={()=>Back_To_Home()}></button>
        
			</div> */}
		</div>
	</div>
</div>


    </div>
  )
}

export default Main_Success