import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Flip, toast } from 'react-toastify';
import { BaseURL } from '../Constants/baseURL';
import { Show_Toast } from '../Constants/Toast';
import { SimpleContext } from '../Context/ContextData';

function Contact() {


  const {
    login_modal,
    setlogin_modal,
    validated,
    Language,
    setValidated,
    Check_Validation,
    API_Call,
    setUser,
    setauthtoken,
  } = useContext(SimpleContext);


  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [subject, setsubject] = useState("")
  const [message, setmessage] = useState("")

  const [loading, setloading] = useState(false)

  const Send_Message  = () => {
    setloading(true)
    var data  = {
      'name' : name,
      'email' : email,
      'subject' : subject,
      'message' : message
    }
    axios.post(`${BaseURL}/user_details/send_enquiry_message/`,data)
    .then((res) => {
      if (res.data.status === 200)
      {   
        console.log(res)
          setloading(false)
          setValidated({contactForm : false})
          Show_Toast(res.data.message , true)   

      }
    })
    .catch((error) => {
      
      setloading(false)
      Show_Toast("Something Went Wrong" , false)   
      setValidated({contactForm : false})
      
    })
  }
  

  return (

    <div className="main">
    
    
      <section className="contact-us-section ptb-100">
        <div className="container">
          <div className="row justify-content-around">
            {/* <div className="col-12 px-5 pb-3 message-box d-none">
              <div className="alert alert-danger" />
            </div> */}
            
            <div className="col-md-5">
              <div className="contact-us-content">
                <div className='row'>
                  <div className='col-12'>
                  <div className="card single-promo-card single-promo-hover text-center">
                <div className="card-body py-5">
                  <div className="pb-2">
                    <span className="ti-home icon-sm color-primary" />
                  </div>
                  <div>
                    <h5 className="mb-0" onClick={()=>Show_Toast("ok" , true )}>{ Language === "en" ? 'Our Address' : 'عنواننا'}</h5>
                    <p className="text-muted mb-0"> 1261 SW 109 Ave <br/>
                    Pembroke Pines , Florida <br/>Zip code 33025
                  </p>
                   
                  </div>
                </div>
              </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-12 col-md-6'>
                  <div className="card single-promo-card single-promo-hover text-center">
                <div className="card-body py-5">
                  <div className="pb-2">
                    <span className="ti-email icon-sm color-primary" />
                  </div>
                  <div>
                    <h5 className="mb-0">{ Language === "en" ? 'Email Us' : 'بريد إلكتروني'}</h5>
                    <p className="text-muted mb-0">info@hello-health.us</p>
                  </div>
                </div>
              </div>
                  </div>
                  
                  <div className='col-12 col-md-6'>
                  <a href="https://api.whatsapp.com/send?phone=0019548019957" target="_blank">
                  <div className="card single-promo-card single-promo-hover text-center">
                <div className="card-body py-5">
                  <div className="pb-2">
                    <span className="ti-mobile icon-sm color-primary" />
                  </div>
                  <div>
                    <h5 className="mb-0">{ Language === "en" ? 'Message' : 'رسالة'}</h5>
                    {/* <p className="text-muted mb-0">+1 (954) 801-9957</p> */}
                  </div>
                </div>
              </div>
              </a>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-us-form  rounded p-1" >
                <Form id="contactForm" className="login-signup-form"
                 noValidate
                 validated={validated.contactForm}
                 onSubmit={(event)=>Check_Validation(event,Send_Message)} >

                  <div className='row'>
                      <div className='col-12 col-md-6'>
                      <div className="form-group">
                <label>{ Language === "en" ? 'Name' : 'اسم'}</label>

                <input
                  type="text"
                  className="form-control"
                 
      
                  required
                  value={ name }
                  onChange ={ (e) =>setname(e.target.value) }

                 
                />

                <Form.Control.Feedback type="invalid">
                { Language === "en" ? 'Provide a Valid Name' : 'أدخل اسمًا صالحًا'}

                </Form.Control.Feedback>
              </div>
                      </div>
                      <div className='col-12 col-md-6'>
                      <div className="form-group">
                <label>{ Language === "en" ? 'E-Mail' : 'بريد إلكتروني'}</label>

                <input
                  type="email"
                  className="form-control"
                 
                  minLength={ 5 }
                  required
                  value={ email }
                  onChange ={ (e) =>setemail(e.target.value) }
                 
                />

                <Form.Control.Feedback type="invalid">
                { Language === "en" ? 'Provide a Valid Email' : 'أدخل بريدًا إلكترونيًا صالحًا'}

                </Form.Control.Feedback>
              </div>
                      </div>


                      <div className='col-12'>
                      <div className="form-group">
                <label>{ Language === "en" ? 'Subject' : 'موضوع'}</label>

                <input
                  type="text"
                  className="form-control"
                 
                
                  required
                  value={ subject }
                  onChange ={ (e) =>setsubject(e.target.value) }
                 
                />

                <Form.Control.Feedback type="invalid">
                { Language === "en" ? 'Provide a Subject' : 'قدم موضوعا'}
                </Form.Control.Feedback>
              </div>
                      </div>

                      <div className='col-12'>
                      <div className="form-group">
                <label>{ Language === "en" ? 'Message' : 'رسالة'}</label>

                <textarea
                  rows={5}
                  type="text"
                  className="form-control"
                 
                  
                  required
                  value={ message }
                  onChange ={ (e) =>setmessage(e.target.value) }
                 
                />

                <Form.Control.Feedback type="invalid">
                  { Language === "en" ? 'Provide a Message' : 'قدم رسالة'}
                </Form.Control.Feedback>
              </div>
                      </div>
                      <div className='col-12' style={{ textAlign:"right"}}>
                        {
                          loading ? 
                          <button disabled = { true } className="btn primary-solid-btn" id="btnContactUs">
                            { Language === "en" ? 'Loading ...' : 'تحميل ...'}
                      </button>: 
                      <button type="submit" className="btn primary-solid-btn" id="btnContactUs">
                      { Language === "en" ?'Send Message' : 'أرسل رسالة'}
                    </button>
                        }
                      
                      </div>



                  </div>
                
                  {/* <div className="form-row">
                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" className="form-control" name="name" id="name" placeholder="Enter name" required />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Enter email" required />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea name="message" id="message" className="form-control" rows={7} cols={25} placeholder="Message" defaultValue={""} />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-3" style={{ textAlign:"right"}}>
                      <button type="submit" className="btn primary-solid-btn" id="btnContactUs">
                        Send Message
                      </button>
                    </div>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    
    </div>


  )
}

export default Contact