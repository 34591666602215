import React, { useContext, useState } from "react";
import { SimpleContext } from "../Context/ContextData";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Show_Toast } from "../Constants/Toast";
import { ToastContainer } from "react-toastify";
import { BFP_Message } from "../Constants/BFPMsg";
import axios from "axios";
import { BaseURL } from "../Constants/baseURL";
import { BMI_Message } from "../Constants/BMI_Message";
import { Message } from "../Constants/Message";

function Auth_Modal() {
  let navigate = useNavigate();
  const {
    login_modal,
    setlogin_modal,
    validated,
    Language ,
    setValidated,
    Check_Validation,
    API_Call,
    setUser,
    setauthtoken,
    assesment_modal,
    setassesment_modal
  } = useContext(SimpleContext);

  const [forgot_password_modal, setforgot_password_modal] = useState(false)
  const [forgot_password_email, setforgot_password_email] = useState("")

  const [register_modal, setregister_modal] = useState(false);
  // const [assesment_modal, setassesment_modal] = useState(false)

  const [loading, setloading] = useState(false);

  const [reg_datas, setreg_datas] = useState({
    email : '',
    age : '',
    fullname : '',
    height_cm : '',
    height_inch : '',
    gender : '',
    weight_kg : '',
    weight_lb : '',
    waist_cm : '',
    waist_inch : '',
    hip_cm : '',
    hip_inch : '',  
  })


  const [register_datas, setregister_datas] = useState({
    email : '',
    password : '',
    fullname : '',
    mobile : '',
    age : '',
    gender  : '',
    prefer_of_communication : 'email'
  })

  const [auth_data, setauth_data] = useState({
    username: "",
    password: "",
  });


  const [assesment_units, setassesment_units] = useState({
    height : 'cm',
    weight : 'kg',
    waits_size : 'cm',
    hip_size : 'cm',
  })

  const [assesment_values, setassesment_values] = useState({
    fullname : '',
    email : '',
    age : '',
    gender : '',
    height : '',
    weight : '',
    waist_size : '',
    hip_size : ''
  })

  const Login = async () => {
    setloading(true);
    var data = {
      username: auth_data.username,
      password: auth_data.password,
    };
    let response = await API_Call("post", "/user_details/get_token/", data);
    setloading(false);
    if (response.status === 200) {
      setauthtoken(response.data);
      setUser(jwt_decode(response.data.access));
      localStorage.setItem("authTokens", JSON.stringify(response.data));
      setValidated(true);
      setauth_data({ username: "", password: "" });
      setlogin_modal(false);
      Show_Toast("Succesfully Logged In",true)
      setValidated({login:false})
      return navigate("/");
    }
    else {
      Show_Toast(response.message  , false )
    }
  };


  const handle_Close = () => {
    setreg_datas({
      email : '',
      password : '',
      age : '',
      fullname : '',
      mobile : '',
      prefer_of_communication : 'email',
      height_cm : '',
      height_inch : '',
      gender : '',
      weight_kg : '',
      weight_lb : '',
      waist_cm : '',
      waist_inch : '',
      hip_cm : '',
      hip_inch : '',  
    })


    setassesment_units ({
      height : 'cm',
        weight : 'kg',
        waits_size : 'cm',
        hip_size : 'cm',
     })


     setValidated({assesment : false })
    
     setassesment_values({
        fullname : '',
        email : '',
        age : '',
        gender : '',
        height : '',
        weight : '',
        waist_size : '',
        hip_size : ''
      })
  }


  const Create_User = async () => {
    setloading(true)
    var data = reg_datas
    data.action = "Create_user"
    data.is_user = true
    data.is_assesment_filled = true

    var BFP = ""
    var BMI = ""
    var msg = ""
    var waist_hip_ratio = ""
    var waist_hip_ratio_msg = ""
    var waist_msg = ""
    var bmi_data = ( data.weight_kg / ( (data.height_cm / 100) * (data.height_cm / 100) ) ).toFixed(2)
    
    BMI = (  703  * ((data.weight_lb)  / ( (data.height_inch) * (data.height_inch) )).toFixed(2))
    waist_hip_ratio = ((data.waist_cm)/(data.hip_cm))

    if (data.gender === "Male")
    {
      BFP = 1.20 * bmi_data +( 0.23 * data.age) - 16.2
      msg = BFP_Message("Male",BFP)
      if (data.waist_cm  < 102 )
      {
        waist_msg = "The amount of fat around your waist area seems to be within the range of the healthy numbers."
      }
      else {
        waist_msg = "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape. "
      }
      if (waist_hip_ratio < 0.9)
      {
        waist_hip_ratio_msg = "Your waist to hip ratio is within the range of the healthy numbers. "
      }
      else
      {
        waist_hip_ratio_msg = "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. "
      }
    }
    else
    {
      BFP = 1.20 * bmi_data + (0.23 * data.age) - 5.4
      
      // msg = await BFP_Message("Female",BFP)
      msg = BFP_Message("Female",BFP)
            if (data.waist_cm < 88 )
      {
        waist_msg = "The amount of fat around your waist area seems to be within the range of the healthy numbers."
      }
      else
      {
        waist_msg = "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape."
      }

      if (waist_hip_ratio < 0.8)
      {
        waist_hip_ratio_msg = "Your waist to hip ratio is within the range of the healthy numbers. "
      }
      else
      {
        waist_hip_ratio_msg = "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. "
      }


    }

    data.bfpvalue = BFP.toFixed(2)
    data.bfpmessage = msg
    data.waist_msg = waist_msg
    data.BMI = BMI.toFixed(2)
    data.msg = "BMI Message"
    data.waist_hip_ratio = waist_hip_ratio.toFixed(2)
    data.waist_hip_ratio_msg = waist_hip_ratio_msg
    let response = await API_Call("post","/user_details/hello_health_user/",data)
    setloading(false)
    if (response.status === 200)
    {

      if (response.data.status  === 200){
        setassesment_modal(false)
        setValidated({ assesment : false })
        Show_Toast(response.data.message,true)
        return navigate('/')
      }
      else{
        Show_Toast(response.data.message , false )
      }
     
    }
    else
    {
      Show_Toast(response.message , false )
    }

  }


  const Signu_up_Data = () => {
    setValidated({register : false })
    setregister_modal(false)
    setassesment_modal(true)
  }


  const  User_register = async () => {
    setloading(true)
    var data = register_datas
    data.action = "Create_user"
    data.is_user = true
    data.is_assesment_filled = true

    let response = await API_Call("post","/user_details/hello_health_user/",data)
    setloading(false)
    if (response.data.status === 200)
    {
      Show_Toast(response.data.message , true)
      setregister_modal( false)
      setregister_datas({
        email : '',
        password : '',
        fullname : '',
        mobile : '',
        age : '',
        gender  : '',
        prefer_of_communication : 'email'
      })
    }
    else
    {
      Show_Toast(response.data.message , false )
    }

  }

  const Send_Email = async (data) => {
    // setchange_pass_loading(true)
    setloading(true)
    var datas = {
      'sitename' : window.location.origin,
      'email' : forgot_password_email
  }
    let send_email_result = await API_Call("post","/user_details/verify_email/",datas)
    setloading(false)
    if (send_email_result.data.status === 200)
    {
      Show_Toast("Reset Link Send to Your Registered E mail",true)
      setforgot_password_email("")
      setValidated({forgotpasswordmodal : false })
      setforgot_password_modal(false)
    }
    else{
      Show_Toast(send_email_result.data.message,false)
    }
  }



  const Assesment_Data_ = async () => {
    
    setloading ( true )
    var data = reg_datas 


    var BFP = ""
    var BMI = ""
    var msg = ""
    var waist_hip_ratio = ""
    var waist_hip_ratio_msg = ""
    var waist_msg = ""
    var bmi_data = ( data.weight_kg / ( (data.height_cm / 100) * (data.height_cm / 100) ) ).toFixed(2)
    
    BMI = (  703  * ((data.weight_lb)  / ( (data.height_inch) * (data.height_inch) )).toFixed(2))
    waist_hip_ratio = ((data.waist_cm)/(data.hip_cm))

    if (data.gender === "Male")
    {
      BFP = 1.20 * bmi_data +( 0.23 * data.age) - 16.2

      data.bfpmessage = BFP_Message("Male",BFP)
      data.bmi_waist_size_message = Message("Male",data.waist_cm,BMI)

      if (data.waist_cm  < 102 )
      {
        waist_msg ={
          'en' : "The amount of fat around your waist area seems to be within the range of the healthy numbers.",
          'ar' : 'يبدو أن كمية الدهون حول منطقة الخصر تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else {
        waist_msg ={
          'en' : "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape. ",
          'ar' : 'كمية الدهون في منطقة البطن أعلى من نطاق الأرقام الصحية. من المحتمل أن يكون لديك جسم على شكل تفاحة.'
        }
      }
      if (waist_hip_ratio < 0.9)
      {
        waist_hip_ratio_msg ={
          'en' : "Your waist to hip ratio is within the range of the healthy numbers. ",
          'ar' : 'نسبة الخصر إلى الورك تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. ",
          'ar' :'نسبة الخصر إلى الورك ليست ضمن نطاق الأرقام الصحية. أنت معرض لخطر الإصابة بمضاعفات التمثيل الغذائي المختلفة بما في ذلك السمنة والسكري وأمراض القلب والأوعية الدموية.'
        }
      }
    }
    else
    {

      data.bmi_waist_size_message = Message("Female",data.waist_cm,BMI)
      BFP = 1.20 * bmi_data + (0.23 * data.age) - 5.4
      

      data.bfpmessage  = BFP_Message("Female",BFP)
      
      
      if (data.waist_cm < 88 )
      {
        waist_msg = {
          'en' : "The amount of fat around your waist area seems to be within the range of the healthy numbers.",
          'ar' : 'يبدو أن كمية الدهون حول منطقة الخصر تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_msg = {
          'en' : "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape.",
          'ar' : 'كمية الدهون في منطقة البطن أعلى من نطاق الأرقام الصحية. من المحتمل أن يكون لديك جسم على شكل تفاحة.'
        }
      }

      if (waist_hip_ratio < 0.8)
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is within the range of the healthy numbers. ",
          'ar' : 'نسبة الخصر إلى الورك تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. ",
          'ar' : 'نسبة الخصر إلى الورك ليست ضمن نطاق الأرقام الصحية. أنت معرض لخطر الإصابة بمضاعفات التمثيل الغذائي المختلفة بما في ذلك السمنة والسكري وأمراض القلب والأوعية الدموية.'
        }
      }


    }


    data.bfpvalue = BFP.toFixed(2)
    // data.bfpmessage = msg
    data.bmi_message = BMI_Message(BMI)
    data.waist_msg = waist_msg
    data.BMI = BMI.toFixed(2)
    data.waist_hip_ratio = waist_hip_ratio.toFixed(2)
    data.waist_hip_ratio_msg = waist_hip_ratio_msg


    axios.post(`${BaseURL}/user_details/send_assesment_email/`,data)
    .then((res) => {
      setloading ( false )
      if (res.data.status === 200)
      {
        Show_Toast(res.data.message , true)
        setassesment_modal ( false )
        setreg_datas({
          email : '',
          age : '',
          fullname : '',
          height_cm : '',
          height_inch : '',
          gender : '',
          weight_kg : '',
          weight_lb : '',
          waist_cm : '',
          waist_inch : '',
          hip_cm : '',
          hip_inch : '',  
        })
      }
      else
      {
        Show_Toast( res.data.message , false)
      }
    })
    .catch((error)  => {
      setloading ( false )

      Show_Toast("Something Went Wrong",false)
    })
  }


  const Send_Assesment_Email = async () => {


    var data = assesment_values

    var height = data.height
    var weight = data.weight
    var waist_size = data.waist_size
    var hip_size = data.hip_size
    var height_in_m = ""
    var BFP = ""
    var msg = ""
    var waist_hip_ratio = ""
    var waist_hip_ratio_msg = ""
    var waist_msg = ""

    if ( assesment_units.height === "inch" )
    {
        height = parseFloat ( data.height ) * 2.54
        height_in_m = parseFloat ( data.height ) * 0.0254
    }

    if ( assesment_units.height === "cm")
    {
      height_in_m = parseFloat ( data.height ) * 0.01
    }

    if ( assesment_units.weight === "lb")
    {
      weight = ( parseFloat ( data.weight ) * 0.4535924 ).toFixed(2)
    }

    if ( assesment_units.waits_size === "inch")
    {
        waist_size = parseFloat ( data.waist_size ) * 2.54
    }

    if ( assesment_units.hip_size === "inch")
    {
        hip_size = parseFloat ( data.hip_size ) * 2.54
    }


    var bmi_value  = ( weight / ( height_in_m ) *  ( height_in_m ) )

    // var bmi_data = ( weight / ( ( height / 100) * ( height / 100) ) ).toFixed(2)

    // BMI = (  703  * ((data.weight_lb)  / ( (data.height_inch) * (data.height_inch) )).toFixed(2))

    waist_hip_ratio = ((waist_size)/(hip_size))

    if (data.gender === "Male")
    {
      BFP = 1.20 * bmi_value +( 0.23 * data.age) - 16.2

      data.bfpmessage = BFP_Message("Male",BFP)
      data.bmi_waist_size_message = Message("Male",waist_size,bmi_value)

      if (waist_size  < 102 )
      {
        waist_msg ={
          'en' : "The amount of fat around your waist area seems to be within the range of the healthy numbers.",
          'ar' : 'يبدو أن كمية الدهون حول منطقة الخصر تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else {
        waist_msg ={
          'en' : "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape. ",
          'ar' : 'كمية الدهون في منطقة البطن أعلى من نطاق الأرقام الصحية. من المحتمل أن يكون لديك جسم على شكل تفاحة.'
        }
      }
      if (waist_hip_ratio < 0.9)
      {
        waist_hip_ratio_msg ={
          'en' : "Your waist to hip ratio is within the range of the healthy numbers. ",
          'ar' : 'نسبة الخصر إلى الورك تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. ",
          'ar' :'نسبة الخصر إلى الورك ليست ضمن نطاق الأرقام الصحية. أنت معرض لخطر الإصابة بمضاعفات التمثيل الغذائي المختلفة بما في ذلك السمنة والسكري وأمراض القلب والأوعية الدموية.'
        }
      }
    }
    else
    {

      data.bmi_waist_size_message = Message("Female",waist_size,bmi_value)
      BFP = 1.20 * bmi_value + (0.23 * data.age) - 5.4

      data.bfpmessage  = BFP_Message("Female",BFP)
            if (waist_size < 88 )
      {
        waist_msg = {
          'en' : "The amount of fat around your waist area seems to be within the range of the healthy numbers.",
          'ar' : 'يبدو أن كمية الدهون حول منطقة الخصر تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_msg = {
          'en' : "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape.",
          'ar' : 'كمية الدهون في منطقة البطن أعلى من نطاق الأرقام الصحية. من المحتمل أن يكون لديك جسم على شكل تفاحة.'
        }
      }

      if (waist_hip_ratio < 0.8)
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is within the range of the healthy numbers. ",
          'ar' : 'نسبة الخصر إلى الورك تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. ",
          'ar' : 'نسبة الخصر إلى الورك ليست ضمن نطاق الأرقام الصحية. أنت معرض لخطر الإصابة بمضاعفات التمثيل الغذائي المختلفة بما في ذلك السمنة والسكري وأمراض القلب والأوعية الدموية.'
        }
      }


    }


    data.bfpvalue = BFP.toFixed(2)
    // data.bfpmessage = msg
    data.bmi_message = BMI_Message(bmi_value)
    data.waist_msg = waist_msg
    data.BMI = bmi_value.toFixed(2)
    data.waist_hip_ratio = waist_hip_ratio.toFixed(2)
    data.waist_hip_ratio_msg = waist_hip_ratio_msg
    data.waist_cm = waist_size  + "Cm"


    axios.post(`${BaseURL}/user_details/send_assesment_email/`,data)
    .then((res) => {
      setloading ( false )
      if (res.data.status === 200)
      {
        Show_Toast(res.data.message , true)
        setassesment_modal ( false )
        setValidated({assesment : false })
       setassesment_units ({
        height : 'cm',
          weight : 'kg',
          waits_size : 'cm',
          hip_size : 'cm',
       })
      
       setassesment_values({
          fullname : '',
          email : '',
          age : '',
          gender : '',
          height : '',
          weight : '',
          waist_size : '',
          hip_size : ''
        })


      
      }
      else
      {
        Show_Toast( res.data.message , false)
      }
    })
    .catch((error)  => {
      setloading ( false )

      Show_Toast("Something Went Wrong",false)
    })




  } 




  const Calculate_Assesment = async () => {

    setloading ( true )
    var data = assesment_values

    var BMI_Value = 0
    var weight_in_kg = 0
    var height_in_m = 0
    var waist_hip_ratio = 0
    var waist_size  = data.waist_size
    var hip_size  = data.hip_size
    var BFP_value = 0
    var waist_msg = ""
    var waist_hip_ratio_msg = ""
    
    if ( assesment_units.weight === "lb")
    {
      weight_in_kg = parseFloat(assesment_values.weight) * parseFloat (0.453592).toFixed(2)
    }
    else
    {
      weight_in_kg = assesment_values.weight
    }

    if ( assesment_units.height === "cm")
    {
      height_in_m = parseFloat(assesment_values.height) * parseFloat (0.01).toFixed(2)
    }
    else
    {
      height_in_m = parseFloat(assesment_values.height) * parseFloat (0.0254)
    }

    if ( assesment_units.waits_size === "inch")
    {
        waist_size  = parseFloat(assesment_values.waist_size) * parseFloat (0.0254)
    }
    if ( assesment_units.hip_size === "inch")
    {
        hip_size  = parseFloat(assesment_values.hip_size) * parseFloat (0.0254)
    }

    BMI_Value =  (parseFloat(weight_in_kg) / (parseFloat(height_in_m) * parseFloat(height_in_m))).toFixed(1)
    waist_hip_ratio = ((waist_size)/(hip_size))

    if (data.gender === "Male"){
      

      BFP_value = ( (parseFloat ( 1.20 ) * parseFloat ( BMI_Value )) + ( parseFloat (0.23 ) * parseInt( data.age ))) - parseFloat ( 16.2 )
      data.bfpmessage = BFP_Message("Male",BFP_value)
      data.bmi_waist_size_message = Message("Male",waist_size,BMI_Value)

      if (waist_size  < 102 )
      {
        waist_msg ={
          'en' : "The amount of fat around your waist area seems to be within the range of the healthy numbers.",
          'ar' : 'يبدو أن كمية الدهون حول منطقة الخصر تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else {
        waist_msg ={
          'en' : "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape. ",
          'ar' : 'كمية الدهون في منطقة البطن أعلى من نطاق الأرقام الصحية. من المحتمل أن يكون لديك جسم على شكل تفاحة.'
        }
      }
      if (waist_hip_ratio < 0.9)
      {
        waist_hip_ratio_msg ={
          'en' : "Your waist to hip ratio is within the range of the healthy numbers. ",
          'ar' : 'نسبة الخصر إلى الورك تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. ",
          'ar' :'نسبة الخصر إلى الورك ليست ضمن نطاق الأرقام الصحية. أنت معرض لخطر الإصابة بمضاعفات التمثيل الغذائي المختلفة بما في ذلك السمنة والسكري وأمراض القلب والأوعية الدموية.'
        }
      }
      
    }
    else
    {
      BFP_value = ( (parseFloat ( 1.20 ) * parseFloat ( BMI_Value )) + ( parseFloat (0.23 ) * parseInt( data.age ))) - parseFloat ( 5.4 )
      data.bmi_waist_size_message = Message("Female",waist_size,BMI_Value)
      data.bfpmessage  = BFP_Message("Female",BFP_value)

      if (waist_size < 88 )
      {
        waist_msg = {
          'en' : "The amount of fat around your waist area seems to be within the range of the healthy numbers.",
          'ar' : 'يبدو أن كمية الدهون حول منطقة الخصر تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_msg = {
          'en' : "The amount of fat in your abdomen area is above the range of the healthy numbers. You likely have a body of an apple shape.",
          'ar' : 'كمية الدهون في منطقة البطن أعلى من نطاق الأرقام الصحية. من المحتمل أن يكون لديك جسم على شكل تفاحة.'
        }
      }

      if (waist_hip_ratio < 0.8)
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is within the range of the healthy numbers. ",
          'ar' : 'نسبة الخصر إلى الورك تقع ضمن نطاق الأرقام الصحية.'
        }
      }
      else
      {
        waist_hip_ratio_msg = {
          'en' :  "Your waist to hip ratio is not within the range of the healthy numbers. You are at risk of different metabolic complications including obesity, diabetes and cardiovascular disease. ",
          'ar' : 'نسبة الخصر إلى الورك ليست ضمن نطاق الأرقام الصحية. أنت معرض لخطر الإصابة بمضاعفات التمثيل الغذائي المختلفة بما في ذلك السمنة والسكري وأمراض القلب والأوعية الدموية.'
        }
      }

    }



    data.bfpvalue = BFP_value.toFixed(2)
    // data.bfpmessage = msg
    data.bmi_message = BMI_Message(BMI_Value)
    data.waist_msg = waist_msg
    data.BMI = BMI_Value
    data.waist_hip_ratio = waist_hip_ratio.toFixed(2)
    data.waist_hip_ratio_msg = waist_hip_ratio_msg
    data.waist_cm = waist_size  + " Cm"
    axios.post(`${BaseURL}/user_details/send_assesment_email/`,data)
    .then((res) => {
      setloading ( false )
      if (res.data.status === 200)
      {
        Show_Toast(res.data.message , true)
        setassesment_modal ( false )
        setValidated({assesment : false })
       setassesment_units ({
        height : 'cm',
          weight : 'kg',
          waits_size : 'cm',
          hip_size : 'cm',
       })
      
       setassesment_values({
          fullname : '',
          email : '',
          age : '',
          gender : '',
          height : '',
          weight : '',
          waist_size : '',
          hip_size : ''
        })
      }
      else
      {
        Show_Toast( res.data.message , false)
      }
    })
    .catch((error)  => {
      setloading ( false )

      Show_Toast("Something Went Wrong",false)
    })
  
  
  }




  return (
    <div>
    <ToastContainer />

      {/* Login Modal */}

      <Modal
        show={login_modal}
        onHide={() => {
          setlogin_modal(false);
          setValidated(true);
        }}
        backdrop="static"
        size="md"
        centered
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              { Language === "en" ? 'Login' : 'تسجيل الدخول'}
            </h5>
            <li
              className="list-inline-item"
              onClick={() => {
                setlogin_modal(false);
                setValidated(false);
              }}
            >
              <span className="fas fa-solid fa-xmark mr-2" />
            </li>
          </div>
          <div className="modal-body">
            <Form
              noValidate
              validated={validated.login}
              id = "loginmodal"
              className="login-signup-form"
              //   onSubmit = {(event) => { id === "" ? Check_Validation(event,Create_Client) : Check_Validation(event,Update_Client) }}
              onSubmit={(event) => Check_Validation(event, Login)}
            >
              <div className="form-group">
                <label className="pb-1">{ Language === "en" ? 'Email' : 'بريد إلكتروني'}</label>

                <div className="input-group input-group-merge">
                  <div className="input-icon">
                    <span className="ti-email color-primary" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    required
                    placeholder={ Language === "en" ? 'Enter Your Username' : 'أدخل اسم المستخدم الخاص بك'}
                    value={auth_data.username}
                    onChange={(e) =>
                      setauth_data({ ...auth_data, username: e.target.value })
                    }
                    
                  />
                  <Form.Control.Feedback type="invalid">
                  <label className="pb-1">{ Language === "en" ? 'Provide a Valid Email' : 'أدخل بريدًا إلكترونيًا صالحًا'}</label>

                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="form-group">
                <label className="pb-1"> <label className="pb-1">{ Language === "en" ? 'Password' : 'كلمة المرور'}</label></label>

                <div className="input-group input-group-merge">
                  <div className="input-icon">
                    <span className="ti-lock color-primary" />
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    required
                    placeholder={ Language === "en" ? 'Enter Your Password' : 'ادخل رقمك السري'}
                    value={auth_data.password}
                    onChange={(e) =>
                      setauth_data({ ...auth_data, password: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                  { Language === "en" ? 'Provide a Password' : 'أدخل كلمة المرور'}
                  </Form.Control.Feedback>
                </div>
              </div>

              {loading ? (
                <button
                  className="btn primary-solid-btn border-radius"
                  style={{ width: "100%" }}
                  disabled={true}
                >
                    { Language === "en" ? 'Loading ...' : 'تحميل ...'}
                </button>
              ) : (
                <button
                  className="btn primary-solid-btn border-radius"
                  style={{ width: "100%" }}
                >
                  { Language === "en" ? 'Login' : 'تسجيل الدخول'}
                </button>
              )}
              <div className="form-group" onClick={()=>{
                setlogin_modal(false);
                setforgot_password_modal(true)
              }}>
                <label className="">{ Language === "en" ? 'Forgot Password ?' : 'هل نسيت كلمة السر ؟'}</label>
              </div>
              <div
                className="form-group"
                onClick={() => {
                  setlogin_modal(false);
                  setregister_modal(true);
                }}
              >
                <label className="">{ Language === "en" ? 'New Member ?' : 'عضو جديد ؟' }</label>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      {/* Register Modal  */}

      <Modal
        show={register_modal}
        onHide={() => setregister_modal(false) & handle_Close()}
        backdrop="static"
        size="md"
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
            { Language === "en" ? 'Register' : 'يسجل' }
            </h5>
            <li
              className="list-inline-item"
              onClick={() => setregister_modal(false)&handle_Close()}
            >
              <span className="fas fa-solid fa-xmark mr-2" />
            </li>
          </div>
          <div className="modal-body">
            <Form
              noValidate
              validated={validated.register}
              id = "registermodal"
              className="login-signup-form"
              //   onSubmit = {(event) => { id === "" ? Check_Validation(event,Create_Client) : Check_Validation(event,Update_Client) }}
                onSubmit={(event)=>Check_Validation(event,User_register)}
            >
              <div className="form-group">
                <label>{ Language === "en" ? 'Email ( Username )' : 'البريد الإلكتروني (اسم المستخدم)' }</label>

                <input
                  type="email"
                  className="form-control"
                  placeholder={ Language === "en" ? 'Enter Your Username' : 'أدخل اسم المستخدم الخاص بك'}
                  required
                  value = { register_datas.email }
                  onChange={(e)=>setregister_datas({...register_datas,email:e.target.value})}
                />

                <Form.Control.Feedback type="invalid">
                { Language === "en" ? 'Provide a Valid Email' : 'أدخل بريدًا إلكترونيًا صالحًا' }

                </Form.Control.Feedback>
              </div>
              <div className="form-group">
                <label>{ Language === "en" ? 'Password' : 'كلمة المرور' }</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder={ Language === "en" ? 'Enter Your Password' : 'ادخل رقمك السري'}
                  minLength={ 5 }
                  required
                  value = { register_datas.password }
                  onChange={(e)=>setregister_datas({...register_datas,password:e.target.value})}
                />

                <Form.Control.Feedback type="invalid">
                  { Language === "en" ? 'Provide a min 5 Letter Password' : 'أدخل كلمة مرور مكونة من 5 أحرف على الأقل' }
                </Form.Control.Feedback>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label> { Language === "en" ? 'Full Name' : 'الاسم الكامل' }</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={ Language === "en" ? 'Enter your Full Name' : 'أدخل اسمك الكامل'}
                      required
                      value = { register_datas.fullname }
                      onChange={(e)=>setregister_datas({...register_datas,fullname:e.target.value})}
                    />

                    <Form.Control.Feedback type="invalid">
                    { Language === "en" ? 'Provide Full Name' : 'أدخل الاسم الكامل' }
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{ Language === "en" ? 'Mobile' : 'متحرك' }</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder={ Language === "en" ? 'Enter Your Number':'أدخل رقمك'}
                     
                      value = { register_datas.mobile }
                      onChange={(e)=>setregister_datas({...register_datas,mobile:e.target.value})}
                    />

                    <Form.Control.Feedback type="invalid">
                    <label>{ Language === "en" ? 'Provide a Valid Mobile Number' : 'أدخل رقم هاتف متحرك ساري المفعول' }</label>
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>{ Language === "en" ? 'Age' : 'عمر'} </label>

                    <input
                      type="text"
                      className="form-control"
                      
                      placeholder={ Language === "en" ? 'Enter Your Age':'أدخل عمرك '}
                      value = { register_datas.age }
                      onChange={(e)=>setregister_datas({...register_datas,age:e.target.value})}
                    />

                    <Form.Control.Feedback type="invalid">
                      { Language === "en" ? 'Provide a Valid Age' : 'حدد عمرًا صالحًا'}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>{ Language === "en" ? 'Gender' : 'جنس'} </label>
                    <select className="form-control has-value" required value={ register_datas.gender} onChange={(e)=>setregister_datas({...register_datas,gender : e.target.value})}>
                      <option defaultChecked value = "" data-display="Domain Registrations">
                        { Language === "en" ? '-- Choose Your Gender --' : '-- اختيار جنسك --'}
                      </option>
                      <option value="Male"> { Language === "en" ? 'Male' : 'ذكر'}</option>
                      <option value="Female"> { Language === "en" ? 'Female' : 'أنثى'}</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                    { Language === "en" ? 'Provide a Gender' : 'توفير الجنس'}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>  { Language === "en" ? 'Prefer of Communication' : 'يفضل الاتصال'}</label>

                <ul
                  className="nav nav-tabs feature-tab feature-new-tab mb-3 border-bottom-0"
                  data-tabs="tabs"
                >
                  <li className="nav-item" onClick={()=>setregister_datas({...register_datas,prefer_of_communication : "email"})}>
                    <a
                      className="nav-link d-flex align-items-center active"
                      href="#feature-tab-1"
                      data-toggle="tab"
                    >
                      <h6 className="mb-0">{ Language ==="en" ? 'Email' : 'بريد إلكتروني'}</h6>
                    </a>
                  </li>
                  <li className="nav-item" onClick={()=>setregister_datas({...register_datas,prefer_of_communication : "phone"})}>
                    <a
                      className="nav-link d-flex align-items-center"
                      href="#feature-tab-2"
                      data-toggle="tab"
                    >
                      <h6 className="mb-0">{ Language ==="en" ? 'Phone' : 'هاتف'}</h6>
                    </a>
                  </li>
                </ul>
              </div>

              {
                loading ?
                <button
                disabled = { true }
                className="btn primary-solid-btn border-radius"
                style={{ width: "100%" }}
              >
                { Language === "en" ? 'Loading ...' : 'تحميل ...'}
              </button> :
              <button
              className="btn primary-solid-btn border-radius"
              style={{ width: "100%" }}
            >
                { Language === "en" ? 'Register' : 'يسجل' }

            </button>

              }

              {/* <button
                className="btn primary-solid-btn border-radius"
                style={{ width: "100%" }}
              >
                Register
              </button> */}
            </Form>
          </div>
          <hr />
        </div>
      </Modal>

      {/* Assesment Form  */}

      <Modal
        show={assesment_modal}
        onHide={() => setassesment_modal(false) & handle_Close()}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              { Language === "en" ? 'Assesment Form' : 'استمارة التقييم'}
            </h5>
            <li className="list-inline-item" onClick={()=>setassesment_modal(false) & handle_Close()}>
              <span className="fas fa-solid fa-xmark mr-2" />
            </li>
          </div>
          <div className="modal-body">
            <Form
              noValidate
              validated={validated.assesment}
              id = "assesment"
              className="login-signup-form"
              //   onSubmit = {(event) => { id === "" ? Check_Validation(event,Create_Client) : Check_Validation(event,Update_Client) }}
                // onSubmit={(event)=>Check_Validation(event,Send_Assesment_Email)}
                onSubmit={(event)=>Check_Validation(event,Calculate_Assesment)}
            >

              

                <div className="form-group">
                
                <div className="row">
                  <div className="col-12 col-md-6">
                  <label className="pb-1">{ Language === "en" ? 'Full Name' : 'الاسم الكامل'}</label>
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="text"
                        required
                        value={ assesment_values.fullname }
                        onChange={(e)=>setassesment_values({...assesment_values , fullname : e.target.value })}
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter Your Full Name' : 'أدخل اسمك الكامل'}
                      />        
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                  <label className="pb-1">{ Language === "en" ? 'Email' : 'بريد إلكتروني'}</label>

                    <div className = "input-group  mr-sm-2">
                      <input
                        type="email"
                        required
                        value={ assesment_values.email }
                        onChange={(e)=>setassesment_values({...assesment_values , email : e.target.value })}
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter Your Email' : 'أدخل بريدك الإلكتروني بالكامل'}
                      />        
                    </div>
                  </div>


                 
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-6">
                <label className="pb-1">{ Language === "en" ? 'Age' : 'عمر'}</label>

                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        value={ assesment_values.age }
                        onChange={(e)=>setassesment_values({...assesment_values , age : e.target.value })}
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter Your Age' : 'أدخل عمرك' }
                        
                        required
                       
                      />
                   
                    </div>
                  </div>
                  <div className="col-6">
                <label className="pb-1">{ Language === "en" ? 'Gender' : 'جنس'}</label>

                  <select className="form-control has-value" required  value={ assesment_values.gender }
                        onChange={(e)=>setassesment_values({...assesment_values , gender : e.target.value })}>
                      <option defaultChecked value = "" data-display="Domain Registrations">
                      { Language === "en" ? '-- Choose Your Gender --' : '-- اختيار جنسك --'}
                      </option>
                      <option value="Male">{ Language === "en" ? 'Male' : 'ذكر'}</option>
                      <option value="Female">{ Language === "en" ? 'Female' : 'أنثى'}</option>
                    </select>
                  </div>
                </div>
              </div>


              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6">
                  <label className="pb-1">{ Language === "en" ? 'Height' : 'ارتفاع'}</label>
                  <div className = "input-group mb-2  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter your Height' : 'أدخل طولك'}
                        
                        required
                        value = { assesment_values.height }
                        onChange = {(e) =>setassesment_values({...assesment_values,height : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text" style={ assesment_units.height === "cm" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, height : 'cm'})} >Cm</div>
                        <div className = "input-group-text" style={ assesment_units.height === "inch" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, height : 'inch'})}>Inch</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                  <label className="pb-1">{ Language === "en" ? 'Weight' : 'وزن'}</label>
                  <div className = "input-group mb-2  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter your Weight' : 'أدخل وزنك'}
                        
                        required
                        value = { assesment_values.weight }
                        onChange = {(e) =>setassesment_values({...assesment_values,weight : e.target.value})}
                      />
                      <div className = "input-group-append">
                      <div className = "input-group-append">
                        <div className = "input-group-text" style={ assesment_units.weight === "kg" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, weight : 'kg'})} >Kg</div>
                        <div className = "input-group-text" style={ assesment_units.weight === "lb" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, weight : 'lb'})}>Lb</div>
                      </div>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 col-md-6">
                  <label className="pb-1">{ Language === "en" ? 'Waist Size' : 'مقاس الخصر'}</label>
                  <div className = "input-group mb-2 mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter Your Waist Size' : 'أدخل حجم الخصر الخاص بك'}
                        
                        required
                        value = { assesment_values.waist_size }
                        onChange = {(e) =>setassesment_values({...assesment_values,waist_size : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text" style={ assesment_units.waits_size === "cm" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, waits_size : 'cm'})} >Cm</div>
                        <div className = "input-group-text" style={ assesment_units.waits_size === "inch" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, waits_size : 'inch'})}>Inch</div>
                      </div>
                    </div>
                  </div>



                  <div className="col-12 col-md-6">
                  <label className="pb-1">{ Language === "en" ? 'Hip Size' : 'قياس الورك'}</label>
                  <div className = "input-group mb-2  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder={ Language === "en" ? 'Enter Your Hip Size' :'أدخل حجم الورك'}
                        
                        required
                        value = { assesment_values.hip_size }
                        onChange = {(e) =>setassesment_values({...assesment_values,hip_size : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text" style={ assesment_units.hip_size === "cm" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, hip_size : 'cm'})} >Cm</div>
                        <div className = "input-group-text" style={ assesment_units.hip_size === "inch" ? { backgroundColor:"#40BCD2" , color:"white" , fontWeight:"bolder" , cursor:"pointer"} : {fontWeight:"bolder",cursor:"pointer"}} onClick={()=>setassesment_units({...assesment_units, hip_size : 'inch'})}>Inch</div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

              {/* <div className="form-group">
                <label className="pb-1">Height</label>
                <div className="row">
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Height"
                        
                        required
                        value = { reg_datas.height_cm }
                        onChange = {(e) =>setreg_datas({...reg_datas,height_cm : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text" style={{ backgroundColor:"#40BCD2" , color:"white" ,fontWeight:"bolder"}} >Cm</div>
                        <div className = "input-group-text">Inch</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Height"
                        
                        required
                        value = { reg_datas.height_inch }
                        onChange = {(e) =>setreg_datas({...reg_datas,height_inch : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Inch</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="pb-1">Weight</label>
                <div className="row">
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Weight"
                        
                        required
                        value = { reg_datas.weight_kg }
                        onChange = {(e) =>setreg_datas({...reg_datas,weight_kg : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Kg</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Weight"
                        
                        required
                        value = { reg_datas.weight_lb }
                        onChange = {(e) =>setreg_datas({...reg_datas,weight_lb : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Lb</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="pb-1">Waist Size</label>
                <div className="row">
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                         placeholder="Enter your Waist Size"
                        
                        required
                        value = { reg_datas.waist_cm }
                        onChange = {(e) =>setreg_datas({...reg_datas,waist_cm : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Cm</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Waist Size"
                        
                        required
                        value = { reg_datas.waist_inch }
                        onChange = {(e) =>setreg_datas({...reg_datas,waist_inch : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Inch</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="pb-1">Hip Size</label>
                <div className="row">
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Hip SIze"
                        
                        required
                        value = { reg_datas.hip_cm }
                        onChange = {(e) =>setreg_datas({...reg_datas,hip_cm : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Cm</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className = "input-group  mr-sm-2">
                      <input
                        type="number"
                        className = "form-control"
                        id="inlineFormInputGroupUsername2"
                      placeholder="Enter your Hip"
                        
                        required
                        value = { reg_datas.hip_inch }
                        onChange = {(e) =>setreg_datas({...reg_datas,hip_inch : e.target.value})}
                      />
                      <div className = "input-group-append">
                        <div className = "input-group-text">Inch</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {
                loading ? 
                <button
                className="btn primary-solid-btn border-radius"
                style={{ width: "100%" }}
                disabled = { true }
              >
                { Language ==="en" ? 'Loading ...' : 'تحميل ...'}
              </button>
              :
              <button
                className="btn primary-solid-btn border-radius"
                style={{ width: "100%" }}
              >
                { Language ==="en" ? 'Register' : 'يسجل'}
              </button>
              }

              
            </Form>
          </div>
          <hr />
        </div>
      </Modal>

      {/* Forgot Password Modal */}

      <Modal
        show={forgot_password_modal}
        onHide={() => {
          forgot_password_modal(false);

        }}
        backdrop="static"
        size="md"
        centered
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {Language === "en" ?  'Forgot Password' : 'هل نسيت كلمة السر'}
            </h5>
            <li
              className="list-inline-item"
              onClick={() => {
                setforgot_password_modal(false);
              }}
            >
              <span className="fas fa-solid fa-xmark mr-2" />
            </li>
          </div>
          <div className="modal-body">
            <Form
              noValidate
              validated={validated.forgotpasswordmodal}
              id = "forgotpasswordmodal"
              className="login-signup-form"
              //   onSubmit = {(event) => { id === "" ? Check_Validation(event,Create_Client) : Check_Validation(event,Update_Client) }}
              onSubmit={(event) => Check_Validation(event, Send_Email)}
            >
              <div className="form-group">
                <label className="pb-1">{ Language  === "en" ? 'Email' : 'بريد إلكتروني'}</label>

                <div className="input-group input-group-merge">
                  <div className="input-icon">
                    <span className="ti-email color-primary" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    required
                    placeholder={ Language === "en" ? 'Enter Your Registered Email' : 'أدخل بريدك الإلكتروني المسجل'}
                    value={forgot_password_email}
                    onChange={(e) =>
                      setforgot_password_email(e.target.value)
                    }
                    
                  />
                  <Form.Control.Feedback type="invalid">
                  { Language  === "en" ? 'Provide a Valid Email' : 'أدخل بريدًا إلكترونيًا صالحًا'}
                  </Form.Control.Feedback>
                </div>
              </div>

              {loading ? (
                <button
                  className="btn primary-solid-btn border-radius"
                  style={{ width: "100%" }}
                  disabled={ true }
                >
                    { Language ==="en" ? 'Loading ...' : 'تحميل ...'}
                </button>
              ) : (
                <button
                  className="btn primary-solid-btn border-radius"
                  style={{ width: "100%" }}
                >
                    { Language ==="en" ? 'Submit' : 'يُقدِّم' }
                </button>
              )}
              {/* <div className="form-group">
                <label className="">Forgot Password ?</label>
              </div> */}
              <div
                className="form-group"
                onClick={() => {
                  setlogin_modal(true);
                  setforgot_password_modal(false);
                }}
              >
                <label className=""> {Language === "en" ?  'Back To Login' : 'العودة إلى تسجيل الدخول'} </label>
              </div>
            </Form>
          </div>
        </div>
      </Modal>


    </div>
  );
}

export default Auth_Modal;
