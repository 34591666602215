import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { SimpleContext } from '../Context/ContextData'



function Header() {
  
  const { setlogin_modal , user , Logout , Language , assesment_modal , setassesment_modal } = useContext(SimpleContext)
  let navigate = useNavigate()
  
  
  const Change_Language = (lng) => {
    localStorage.setItem('hl_lng' , lng)
    window.location.reload()
  }



  return (
    <header id="header" className="header-main">
  
    <div id="logoAndNav" className="main-header-menu-wrap white-bg border-bottom">
      <div className="container">
        <nav className="js-mega-menu navbar navbar-expand-md header-nav">
         
          <a className="navbar-brand" href="/"><img src="/assets/img/hellohealth/Logo/logo.png" width={120} alt="logo" className="img-fluid" /></a>
        
          <button type="button" className="navbar-toggler btn" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
            <span id="hamburgerTrigger">
              <span className="fas fa-bars" />
            </span>
          </button>

      
          
          <div id="navBar" className="collapse navbar-collapse">
            <ul className="navbar-nav ml-auto main-navbar-nav">
          
              <li className="nav-item  custom-nav-item" data-position="left">
                {/* <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}} aria-haspopup="true" aria-expanded="false" onClick={()=>{ return navigate("/")}} >{ Language === "en" ? 'Home' : 'بيت'}</a> */}
                <a  className="nav-link custom-nav-link" href='/' style={{ cursor:"pointer"}} aria-haspopup="true" aria-expanded="false"  >{ Language === "en" ? 'Home' : 'بيت'}</a>
              </li>


              {/* <li className="nav-item   custom-nav-item" data-position="left">
                <a id="homeMegaMenu" className="nav-link custom-nav-link" href={ undefined } onClick={()=> { return navigate('/About')}} aria-haspopup="true" aria-expanded="false">Home</a>          
              </li>  */}

              

              <li className="nav-item  custom-nav-item" data-position="left">
                {/* <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false"  onClick={()=>{ return navigate("/About")}} >{ Language === "en" ? 'About' : 'عن'}</a> */}
                <a  className="nav-link custom-nav-link" href='/About' style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false"  >{ Language === "en" ? 'About' : 'عن'}</a>
              </li>

              <li className="nav-item  custom-nav-item" data-position="left">
                {/* <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false" onClick={()=>{ return navigate("/Calculator")}} >{ Language === "en" ? 'Calculator' : 'آلة حاسبة'}</a> */}
                <a  className="nav-link custom-nav-link" href='/Calculator' style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false"  >{ Language === "en" ? 'Calculator' : 'آلة حاسبة'}</a>
              </li>

              <li className="nav-item  custom-nav-item" data-position="left">
                <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false"  onClick={()=>setassesment_modal( true )} >{ Language === "en" ? 'Assesment' : 'تقدير' }</a>
              </li>

              <li className="nav-item  custom-nav-item" data-position="left">
                {/* <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false" onClick={()=>{ return navigate("/Contact")}} >{ Language === "en" ? 'Contact' : 'اتصال' }</a> */}
                <a  className="nav-link custom-nav-link" href='/Contact' style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false"  >{ Language === "en" ? 'Contact' : 'اتصال' }</a>
              </li>

              {
                user != null ?
                <li className="nav-item  custom-nav-item" data-position="left">
                {/* <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false" onClick={()=>{ return navigate("/mychallenges")}} >{ Language === "en" ? 'My Challenges' : 'تحدياتي' }</a> */}
                <a  className="nav-link custom-nav-link" href='/mychallenges' style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false" >{ Language === "en" ? 'My Challenges' : 'تحدياتي' }</a>
              </li>: ''
              }

              {
                user != null ? 
                <li className="nav-item  custom-nav-item" data-position="left">
                  {/* <a  className="nav-link custom-nav-link" href={ undefined } style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false" onClick={()=>{ return navigate("/myprogrammes")}} >{ Language === "en" ? 'My Programmes' : 'برامجي' }</a> */}
                  <a  className="nav-link custom-nav-link" href='/myprogrammes' style={{ cursor:"pointer"}}  aria-haspopup="true" aria-expanded="false"  >{ Language === "en" ? 'My Programmes' : 'برامجي' }</a>
                </li>: ''
              }

              <li className="nav-item hs-has-sub-menu custom-nav-item">
                <a id="pagesMegaMenu" className="nav-link custom-nav-link main-link-toggle"  href={ undefined } aria-haspopup="true" aria-expanded="false" aria-labelledby="pagesSubMenu">
                <li className="list-inline-item" style={{ cursor:"pointer"}} ><span className="fas fa-soid fa-globe mr-2" style={{ fontSize:"25px"}} /></li>

                </a>
                
                <ul id="pagesSubMenu" className="hs-sub-menu main-sub-menu" aria-labelledby="pagesMegaMenu" style={{minWidth: 260, maxHeight:"100px" }}>
                  <li className="nav-item submenu-item" style={{ cursor:"pointer"}}  onClick={()=> Change_Language('en')}>
                    <a className="nav-link sub-menu-nav-link" href={ undefined }>English</a>
                  </li>
                  <li className="nav-item submenu-item" style={{ cursor:"pointer"}}  onClick={()=>Change_Language('ar')}>
                    <a className="nav-link sub-menu-nav-link" href={ undefined }>عربي</a>
                  </li>
                  
                </ul>
              
              </li>

             

              <li className="nav-item hs-has-sub-menu custom-nav-item">
                <a id="pagesMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-labelledby="pagesSubMenu">
                <li className="list-inline-item" style={{ cursor:"pointer"}} ><span className="fas fa-user mr-2" /></li>
                </a>
               
                <ul id="pagesSubMenu" className="hs-sub-menu main-sub-menu" aria-labelledby="pagesMegaMenu" style={{minWidth: 260 ,maxHeight:"60px" }}>
                  {
                    user != null ?
                    <li className="nav-item submenu-item" onClick={Logout}>
                    <a className="nav-link sub-menu-nav-link" style={{ cursor:"pointer"}}  href={ undefined }>{ Language === "en" ? 'Logout' : 'تسجيل خروج' }</a>
                  </li> :
                   <li className="nav-item submenu-item" onClick={()=>setlogin_modal(true)}>
                   <a className="nav-link sub-menu-nav-link"  style={{ cursor:"pointer"}}  href={ undefined }>{ Language === "en" ? 'Login' : 'تسجيل الدخول' }</a>
                 </li>
                  }
                 
              
              
                  
                </ul>
                
              </li>


              {/* <li className="nav-item custom-nav-item" data-position="left">
                <a id="homeMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } onClick={()=> { return navigate('/About')}} aria-haspopup="true" aria-expanded="false">Home</a>          
              </li> */}
            
              {/* <li className="nav-item hs-has-sub-menu custom-nav-item">
                <a id="pagesMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-labelledby="pagesSubMenu">Pages</a>
              
                <ul id="pagesSubMenu" className="hs-sub-menu main-sub-menu" aria-labelledby="pagesMegaMenu" style={{minWidth: 260}}>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="about-us.html">About Us</a>
                  </li>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="services.html">Our Services</a>
                  </li>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="reviews.html">Client Reviews</a>
                  </li>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="domain-search-result.html">Search Result</a>
                  </li>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="image-gallery.html">Image Gallery</a>
                  </li>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="black-friday-sale-page.html">Black Friday Sale <span className="badge badge-warning ml-auto">New</span></a>
                  </li>
                  <li className="nav-item submenu-item">
                    <a className="nav-link sub-menu-nav-link" href="offer-single-page.html">Black Friday Single <span className="badge badge-warning ml-auto">New</span></a>
                  </li>
                  <li className="hs-has-sub-menu">
                    <a id="navLinkPagesPricing" className="nav-link sub-menu-nav-link sub-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuPagesPricing">Pricing</a>
                    <ul id="navSubmenuPagesPricing" className="hs-sub-menu main-sub-menu" aria-labelledby="navLinkPagesPricing" style={{minWidth: 230}}>
                      <li><a className="nav-link sub-menu-nav-link" href="pricing-default.html">Pricing Default</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="pricing-package.html">Pricing Packages</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="pricing-comparision.html">Pricing Comparison</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="vps-pricing.html">VPS Pricing</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="pricing-new.html">New Pricing <span className="badge badge-success ml-2">New</span></a></li>
                    </ul>
                  </li>
                  <li className="hs-has-sub-menu">
                    <a id="navLinkPagesBlog" className="nav-link sub-menu-nav-link sub-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuPagesBlog">Blog</a>
                    <ul id="navSubmenuPagesBlog" className="hs-sub-menu main-sub-menu" aria-labelledby="navLinkPagesBlog" style={{minWidth: 230}}>
                      <li><a className="nav-link sub-menu-nav-link" href="blog-grid.html">Blog Grid</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="blog-sidebar.html">Blog With Sidebar</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="blog-details.html">Blog Details</a></li>
                    </ul>
                  </li>
                  <li className="hs-has-sub-menu">
                    <a id="navLinkPagesLogin" className="nav-link sub-menu-nav-link sub-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuPagesLogin">Login &amp; Signup</a>
                    <ul id="navSubmenuPagesLogin" className="hs-sub-menu main-sub-menu" aria-labelledby="navLinkPagesLogin" style={{minWidth: 230}}>
                      <li><a className="nav-link sub-menu-nav-link" href="login.html">Login</a>
                      </li>
                      <li><a className="nav-link sub-menu-nav-link" href="signup.html">Signup</a>
                      </li>
                      <li><a className="nav-link sub-menu-nav-link" href="recover-account.html">Recover Account</a></li>
                      <li className="dropdown-divider" />
                      <li><a className="nav-link sub-menu-nav-link" href="login-simple.html">Login Simple</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="signup-simple.html">Signup Simple</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="recover-account-simple.html">Recover Account Simple</a>
                      </li>
                    </ul>
                  </li>
                  <li className="hs-has-sub-menu">
                    <a id="navLinkPagesUtilities" className="nav-link sub-menu-nav-link sub-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuPagesUtilities">Utilities</a>
                    <ul id="navSubmenuPagesUtilities" className="hs-sub-menu main-sub-menu" aria-labelledby="navLinkPagesUtilities" style={{minWidth: 230}}>
                      <li><a className="nav-link sub-menu-nav-link" href="help.html">Help</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="faq.html">FAQ</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="status.html">Status</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="knowledge-base.html">Knowledge Base</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="terms-condition.html">Terms
                          &amp; Conditions</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="privacy-policy.html">Privacy &amp; Policy</a></li>
                    </ul>
                  </li>
                  <li className="hs-has-sub-menu">
                    <a id="navLinkPagesSpecialty" className="nav-link sub-menu-nav-link sub-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuPagesSpecialty">Specialty</a>
                    <ul id="navSubmenuPagesSpecialty" className="hs-sub-menu main-sub-menu" aria-labelledby="navLinkPagesSpecialty" style={{minWidth: 230}}>
                      <li><a className="nav-link sub-menu-nav-link" href="cover-page.html">Cover Page</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="coming-soon.html">Coming Soon</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="maintenance-mode.html">Maintenance Mode</a></li>
                      <li><a className="nav-link sub-menu-nav-link" href="404.html">Error 404</a></li>
                    </ul>
                  </li>
                
                </ul>
                
              </li> */}
           
              {/* <li className="nav-item  custom-nav-item" data-max-width="720px" data-position="right">
                <a id="hostingMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false">Hosting</a>

                <div className="hs-mega-menu w-100 main-sub-menu" aria-labelledby="hostingMegaMenu">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                   
                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="shared-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-server" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">Shared Hosting</span>
                              <small className="u-header__promo-text">Innovate reliable quality
                                Starting at <strong>$2.99</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                   
                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="vps-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-box" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">VPS Hosting <span className="badge badge-success ml-1">Popular</span></span>
                              <small className="u-header__promo-text">Pefficiently maintain
                                Starting at <strong>$11.99</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                  
                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="dedicated-server-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-tachometer-alt" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">Dedicated Hosting</span>
                              <small className="u-header__promo-text">Quickly build Starting at
                                <strong>$150.99/mo</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                     
                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="cloud-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-cloud" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">Cloud Hosting <span className="badge badge-danger ml-1">Hot</span></span>
                              <small className="u-header__promo-text">Conveniently cloud Starting
                                at <strong>$5.99/mo</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                  
                    </div>
                    <div className="col-md-6">
                
                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="email-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-envelope" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">Email Hosting</span>
                              <small className="u-header__promo-text">First Starting at <strong>$0.99/mo
                                  per mailbox</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
              
                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="shared-wp-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-database" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">Shared Wordpress Hosting</span>
                              <small className="u-header__promo-text">Conveniently Starting at
                                <strong>$9.99/mo</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                    

                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="wp-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fab fa-wordpress" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">Wordpress Hosting</span>
                              <small className="u-header__promo-text">Conveniently Starting at
                                <strong>$9.99/mo</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                    

                      <div className="title-with-icon-item">
                        <a className="title-with-icon-link" href="woocommerce-hosting.html">
                          <div className="media">
                            <div className="menu-item-icon"><i className="fas fa-server" /></div>
                            <div className="media-body">
                              <span className="u-header__promo-title">WooCommerce Hosting <span className="badge badge-primary ml-1">New</span></span>
                              <small className="u-header__promo-text">Globally Starting at
                                <strong>$9.99/mo</strong></small>
                            </div>
                          </div>
                        </a>
                      </div>
                    
                    </div>
                  </div>
                </div>
               
              </li> */}
             
           
              {/* <li className="nav-item  custom-nav-item" data-max-width="600px" data-position="right">
                <a id="elementsMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false">Elements</a>
               
                <div className="hs-mega-menu main-sub-menu u-header__mega-menu-position-right-fix--md" aria-labelledby="elementsMegaMenu">
                  <div className="mega-menu-wrap">
                    <span className="sub-menu-title">Individual Elements and Blocks</span>
                    <div className="row">
                      <div className="col-sm-4">
                        <ul className="sub-menu-nav-group">
                          <li><a className="nav-link sub-menu-nav-link" href="accordions.html">Accordions</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="breadcrumbs.html">Breadcrumbs</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="buttons.html">Buttons</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="clients.html">Clients</a></li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="sub-menu-nav-group">
                          <li><a className="nav-link sub-menu-nav-link" href="carousel.html">Carousel</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="call-to-action.html">Call to Action</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="testimonials.html">Testimonials</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="typography.html">Typography</a></li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="sub-menu-nav-group">
                          <li><a className="nav-link sub-menu-nav-link" href="team.html">Team</a>
                          </li>
                          <li><a className="nav-link sub-menu-nav-link" href="tabs.html">Tab</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="videos.html">Videos</a></li>
                          <li><a className="nav-link sub-menu-nav-link" href="forms.html">Form</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
              
                  <div className="secondary-bg">
                    <div className="u-header__product-banner-content position-relative z-index text-center text-white">
                      <div className="mb-4">
                        <h5 className="text-white">Get 25% Discount Start Now</h5>
                        <div className="clock menu-countdown my-4 text-white" />
                      </div>
                      <a className="btn solid-white-btn" href="#">Get Started<span className="ti-arrow-right pl-2" /></a>
                    </div>
                  </div>
                
                </div>
             
              </li> */}
             
              {/* <li className="nav-item  custom-nav-item" data-max-width="360px" data-position="right">
                <a id="supportMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false">Support</a>
             
                <div className="hs-mega-menu main-sub-menu" aria-labelledby="supportMegaMenu" style={{minWidth: 330}}>
                
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="submit-request.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/chat.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Submit a Request </span>
                          <small className="u-header__promo-text">Support team quickly response
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                 
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="support.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/support.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">24/7/365 Support</span>
                          <small className="u-header__promo-text">Support and statement</small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                  
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="network.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/data-services.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Our Datacenter</span>
                          <small className="u-header__promo-text">24 Data center globally</small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                 
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="status.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/time.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Uptime Commitment</span>
                          <small className="u-header__promo-text">Monitoring and secure</small>
                        </div>
                      </div>
                    </a>
                  </div>
                
               
                  <div className="u-header__promo-footer">
                   
                    <div className="row no-gutters">
                      <div className="col-6">
                        <div className="u-header__promo-footer-item">
                          <small className="text-muted d-block">Check what's new</small>
                          <a className="small" href="#">
                            Changelog
                          </a>
                        </div>
                      </div>
                      <div className="col-6 u-header__promo-footer-ver-divider">
                        <div className="u-header__promo-footer-item">
                          <small className="text-muted d-block">Have a question?</small>
                          <a className="small" href="contact-us.html">
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                  
                </div>
                
              </li> */}
             
              {/* <li className="nav-item  custom-nav-item" data-max-width="250px" data-position="right">
                <a id="aboutMegaMenu" className="nav-link custom-nav-link main-link-toggle" href={ undefined } aria-haspopup="true" aria-expanded="false">About</a>
                
                <div className="hs-mega-menu main-sub-menu" aria-labelledby="aboutMegaMenu" style={{minWidth: 330}}>
                 
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="contact-us.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/chat-mobile.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Contact Us</span>
                          <small className="u-header__promo-text">Face any problem contact with us
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="about-us.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/community.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">About Us</span>
                          <small className="u-header__promo-text">We are leading hosting company
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
               
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="reviews.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/user-behaver.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Client Testimonial</span>
                          <small className="u-header__promo-text">More than 100k lovly customer
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                 
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="partners.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/partner.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Our Partner</span>
                          <small className="u-header__promo-text">We have trusted partners globally
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                 
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="network.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/network.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Network</span>
                          <small className="u-header__promo-text">Keep your data save and secure
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                 
                  <div className="title-with-icon-item">
                    <a className="title-with-icon-link" href="blog-grid.html">
                      <div className="media align-items-center">
                        <img className="menu-titile-icon" src="assets/img/blog.svg" alt="SVG" />
                        <div className="media-body">
                          <span className="u-header__promo-title">Company Blog</span>
                          <small className="u-header__promo-text">Industry latest technology news
                          </small>
                        </div>
                      </div>
                    </a>
                  </div>
                
                 
                  <div className="u-header__promo-footer pb-0">
                    
                    <div className="row no-gutters align-bottom">
                      <div className="col-9">
                        <div className="u-header__promo-footer-item">
                          <span className="u-header__promo-title">CEO Video Message</span>
                          <p className="small d-inline-flex">Credibly synergize unique... <a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="popup-youtube video-play-icon"><span className="ti-control-play" /> </a></p>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="d-flex align-bottom">
                          <img src="assets/img/ceo.png" width={300} alt="ceo" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
                
              </li> */}
              
              
              {/* <li className="nav-item header-nav-last-item d-flex align-items-center">
                <a className="btn primary-solid-btn animated-btn" href="#" target="_blank">
                  Get Started
                </a>
              </li> */}
           
            </ul>
          </div>
        
        </nav>
      </div>
    </div>
   
  </header>
  )
}

export default Header