import React from 'react'
import { Route , Routes } from 'react-router-dom'
import Outlet_Connection from './Connection/Outlet_Connection'
import About from './pages/About'
import Calculator from './pages/Calculator'
import Challenge_Page from './pages/Challenge_Page'
import Change_Password from './pages/Change_Password'
import Contact from './pages/Contact'
import Error_Message from './pages/Error_Message'
import Home from './pages/Home'
import Main_Error from './pages/Main_Error'
import Main_Success from './pages/Main_Success'
import My_Challenges from './pages/My_Challenges'
import My_Programmes from './pages/My_Programmes'
import Programme_Page from './pages/Programme_Page'
import Read_More from './pages/Read_More'
import Success from './pages/Success'
import Success_Message from './pages/Success_Message'
function App() {
  return (
    <Routes>
      <Route path='/' element={<Outlet_Connection />}>
        <Route index element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Calculator' element={<Calculator />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/challenge/:id' element={<Challenge_Page />} />
        <Route path='/programme/:id' element={<Programme_Page />} />
        <Route path='/mychallenges' element={<My_Challenges />} />
        <Route path='/myprogrammes' element={<My_Programmes />} />
        <Route path='/read-more' element={<Read_More />} />
      </Route>
      <Route path='/success' element={<Main_Success />} />
      <Route path='/error' element={<Main_Error />} />
      <Route path='/success/:session_id/:saved_id/:type' element={<Success_Message />} />
      <Route path='/canceled=true/:saved_id/:type' element={ <Error_Message /> } />
      <Route path='/user_details/password-reset/:str/:id/' element={ <Change_Password /> } />
      {/* <Route path='/change' element={ <Change_Password /> } /> */}
    </Routes>
  )
}

export default App