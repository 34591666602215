import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SimpleContext } from '../Context/ContextData'

function Error_Message() {


    let navigate = useNavigate()

  const [loader, setloader] = useState(true)
  const { API_Call , user , Language  } = useContext(SimpleContext)

  let params= useParams()


  useEffect(() => {
    Update_Enrolled_Data()
  }, [])


  const Update_Enrolled_Data = async () => {


    console.log(user.username)
      var data = {
        "saved_id" : params.saved_id,
        "type" : params.type,
      }
      let datas  = await API_Call("post","/enroll/remove_order/",data)
      console.log(datas)
      if (datas.status === 200)
      {
        console.log( datas )
        setloader(false)
        return navigate('/error')

      }
      else
      {
        setloader(false)
      }


  }




  return (
    <center>
      {
        loader ? 
        <>
        <div class="loader" style={{ marginTop:"25%"}}></div>
        Loading Your Data Please Wait
        </>
        :
        ''
      }
      
    </center>

  )
}

export default Error_Message