import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SimpleContext } from '../Context/ContextData'
import '../Css/LoaderCss.css'

function Success_Message() {

    let navigate = useNavigate()

  const [loader, setloader] = useState(true)
  const { API_Call , user , Language  } = useContext(SimpleContext)
  let params= useParams()

  useEffect(() => {
    Update_Enrolled_Data()
  }, [])
  

  const Update_Enrolled_Data = async () => {

    console.log(user.username)
      var data = {
        "session_id" : params.session_id,
        "saved_id" : params.saved_id,
        "type" : params.type,
        "email" : user.username
      }
      let datas  = await API_Call("post","/enroll/payment_orders/",data)
      console.log(datas)
      if (datas.status === 200)
      {
        console.log( datas )
        setloader(false)
        localStorage.removeItem('enr_id')
        return navigate('/success')

      }
      else
      {
        setloader(false)
      }


  }


  return (
    <center>
      {
        loader ? 
        <>
        <div class="loader" style={{ marginTop:"25%"}}></div>
        { Language === "en" ? 'Loading Your Data Please Wait' : 'تحميل البيانات الخاصة بك يرجى الانتظار'}
        </>
        :
        ''
      }
      
    </center>
  )
}

export default Success_Message