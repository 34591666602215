import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseURL } from '../Constants/baseURL'
import { SimpleContext } from '../Context/ContextData'
import moment from 'moment'
import { Form  } from 'react-bootstrap'
import axios from 'axios'
import { Show_Toast } from '../Constants/Toast'

function Programme_Page() {

    let params = useParams()
    let navigate = useNavigate()

    const [discount_price, setdiscount_price] = useState(null)
    const [promocode_loading, setpromocode_loading] = useState(false)
    const [appplied_promocode, setappplied_promocode] = useState(null)

    const [promocode, setpromocode] = useState("")

    const [loading, setloading] = useState(false)



    const { API_Call , Language , Check_Validation , setValidated , validated , user , authtoken } = useContext(SimpleContext)
    const [programme_details, setProgramme_details] = useState("")

    useEffect(() => {
        Get_Programme_Details()
        Is_Enrolled_Check()
    }, [])


    const Is_Enrolled_Check = async () => {
      const enrolled_data = localStorage.getItem('enr_id')
  
      if (enrolled_data) {
        var data = {
          "saved_id" : enrolled_data,
          "type" : "Programme",
        }
        let datas  = await API_Call("post","/enroll/remove_order/",data)
        console.log(datas)
        if (datas.status === 200)
        {       
          localStorage.removeItem('enr_id')
          return navigate('/error')
        }
        else
        {
          return navigate('/')
        }
      }
      else {
        Get_Programme_Details()
      }
    }


    const Get_Programme_Details = async () => {
        var data = {
            'id' : params.id
        }

        let response = await API_Call("get","/programme_details/all_programmes/",data)
        if (response.status != 200 || response.data.message.length === 0)
        {
            return navigate('/')
        }
        else
        {
            setProgramme_details(response.data.message[0])
        }

    }

    
  const Add_Coupoun_Code  = async (data) => { 
    setpromocode_loading(true)
    var data  = {
      "programme_id" : programme_details.id,
      "promocode" : promocode
    }
    let response = await API_Call("post","/programme_details/apply_coupouncode/",data)
    console.log(response)
    setpromocode_loading(false)
    if (response.data.status === 200) 
      {

        console.log("THE PROMOCODE")
        setpromocode_loading(false)
        var applied_discount = ((programme_details.programme_price) - ((programme_details.programme_price * response.data.programme_data[0].discount_percentage) / 100 ))
        setdiscount_price(Math.round(applied_discount))
        setappplied_promocode(response.data.programme_data[0].id)
        setValidated({validateprogramme : false})
        Show_Toast(response.data.message , true)
      }
    else{
      Show_Toast(response.data.message , false)

    }
  }

  const Enroll_Free_Programme = async () => {

    setloading(true)

    var datas = {
      "user_id" : user.user_id,
      "programme_details" : programme_details.id,
      "is_free" : true,
      "paid_status" : "Free of Cost"
    }

    let response = await API_Call("post","/enroll/enroll_free_programme/",datas)
    setloading(false)
    if (response.status === 200)
    {

      return navigate('/success')
    }
    else

    {
      Show_Toast("Something Went Wrong" , false)
    }
    
    
    
  }

  
  const Enroll_Paid_Programme =  async () => {
    setloading(true)
    var datas = {
      "user_id" : user.user_id,
      "programme_details" : programme_details.id,
      "is_free" : false,
      "paid_status" : "UnPaid",
      "coupouncode_details" : appplied_promocode,
      "programme_price" : programme_details.programme_price,
      "discount_price" : discount_price,
      "payable_amount" : `${discount_price === null ? programme_details.programme_price : discount_price } USD`,
    }

    let response = await API_Call("post",'/enroll/enroll_paid_programme/',datas)
    if (response.status === 200){
      localStorage.setItem('enr_id',response.data.saved_id)
      Payment_Function(response.data.saved_id)
    }
    else
    {
      Show_Toast("Something Went Wrong", false)
    }
   

  }


  const Payment_Function = async (enroll_id) => {
    setloading(true)
    var data = {
      "name" : programme_details.programmename_english ,
      "price" : `${discount_price === null ? programme_details.programme_price * 100 : discount_price * 100 }`,
      "origin_site" : window.location.origin,
      "enroll_id" : enroll_id,
      "type" : "Programme"
    }
    await axios.post(`${BaseURL}/enroll/create-checkout-session/`,data,{
      headers : {
        Authorization :  `Bearer ${authtoken.access}`
      }
    })
    .then((res) => {
      // console.log(res.data)
     
      window.location.assign(res.data.message.url); 
      // return navigate(res.data.message.url)
    })
    .catch((error) => {
      Show_Toast("Something Went Wrong" , false)
    })
  }


    
  return (
    <div className="main">
    <section className="our-team-section ptb-100 gray-light-bg" style={{ paddingTop:"30px"}}>
    <div className="container">
        <div className='row'>
        <div className='col-12 col-md-6'>
            <img src={`${BaseURL}${programme_details.programme_image}`} alt="team image" className="card-img-top" style={{ maxHeight:"350px"}} />
        </div>
        <div className='col-12 col-md-6' style={{ marginTop:"40px"}}>
        <div class="features-box p-4">
         
            <div class="features-box-content">
                <h3>
                    {Language === "en" ? `${programme_details.programmename_english}` : `${programme_details.programmename_arabic}` }
                    <p>
                      {
                        programme_details.programme_price === null || programme_details.programme_price === 0 || programme_details.programme_price === "" ? `${ Language === "en" ? 'Free Programme' : 'برنامج مجاني'}`: `${programme_details.programme_price} USD` 
                      }
                    </p>

                </h3>
               
            </div>
        </div>
        </div>
        </div>

        <div className='row mt-4'>
            <div className='col-12 col-md-6' style={{ marginTop:"40px"}}>
                {
                    programme_details.programme_price === null || programme_details.programme_price === 0 || programme_details.programme_price === "" ? '':
                    <>
                     <h6>{ Language === "en" ? 'Apply Coupun Code' : 'تطبيق رمز القسيمة'} <li className="ml-3 list-inline-item"><span className="fas fa-solid fa-circle-info mr-2" /></li></h6>

                        <Form className="d-md-block d-lg-block mt-2 " id="validateprogramme" noValidate validated={validated.validateprogramme} onSubmit={(event) =>Check_Validation(event,Add_Coupoun_Code)}>
                            <input value={promocode} onChange={(e)=>setpromocode(e.target.value)} required type="text" className="form-control input"  name="email" placeholder={ Language === "en" ? 'Enter Your Coupon Code' : 'أدخل رمز القسيمة الخاص بك' }/>
                            <Form.Control.Feedback type="invalid">
                                { Language === "en" ? 'Please Provide a Coupoun Code' : 'يرجى تقديم رمز القسيمة'}
                            </Form.Control.Feedback>
                            {
                              promocode_loading ? 
                            <button disabled = { true } className="btn primary-solid-btn btn-block btn-not-rounded mt-3"> { Language === "en" ? 'Loading ...' : 'تحميل ...'}</button>
                              :
                            <button type="submit" className="btn primary-solid-btn btn-block btn-not-rounded mt-3"> { Language === "en" ? 'Apply Coupoun Code' : 'تطبيق رمز القسيمة'}</button>

                            }
                        </Form>
                    </>
                }
           

            </div>

            <div className='col-12 col-md-6' style={{ marginTop:"40px"}}>
            <h6>{ Language === "en" ? 'Pricing Details' : 'تفاصيل التسعير'}</h6>

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colSpan={2} style={{ textAlign:"center"}}>{ Language === "en" ? 'Pricing Details' : 'تفاصيل التسعير'}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        programme_details.programme_price === null || programme_details.programme_price === 0 || programme_details.programme_price === "" ?
                        <>
                        <tr>
                            <th scope="row" style={{ textAlign:"center"}}>{ Language === "en" ? 'Total Price' : 'السعر الكلي'}</th>
                            <td style={{ textAlign:"center"}}><b>{ Language === "en" ? 'Free' : 'حر'}</b></td>
                        </tr>
                       </> 
                       :
                       <>
                       <tr>
                      <th scope="row" style={{ textAlign:"center"}}>{ Language === "en" ? 'Total Price' : 'السعر الكلي'}</th>
                      <td style={{ textAlign:"center"}}><b>{ programme_details.programme_price }</b></td>
                    
                    </tr>
                    <tr>
                      <th scope="row" style={{ textAlign:"center"}}>{ Language === "en" ? 'Discount Price' : 'سعر الخصم'}</th>
                      <td style={{ textAlign:"center"}}><b>{ discount_price === null ? `No Discount Added` : discount_price }</b></td>
                   
                    </tr>
                    <tr>
                      <th scope="row" style={{ textAlign:"center"}}>{ Language === "en" ?'Sub Total' : 'المجموع الفرعي'}</th>
                      <td style={{ textAlign:"center"}}><b>{ discount_price === null ? programme_details.programme_price : discount_price }</b></td>
                    </tr>
                       </>
                    }
                    
                </tbody>
                </table>
                {
                    programme_details.programme_price === 0 || programme_details.programme_price === null || programme_details.programme_price === "" ?
                    <>
                    {
                      loading ? 
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" disabled= { true } > { Language === "en" ? 'Loading ...' : 'تحميل ...'} </button>
                      :
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" onClick={Enroll_Free_Programme} > { Language === "en" ? 'Enroll Now' : 'تسجيل الآن'} </button>

                    }
                    </>
                    :
                    <>
                    {
                      loading ? 
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" disabled = { true } > { Language === "en" ? 'Loading ...' : 'تحميل ...'} </button>
                      :
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" onClick={Enroll_Paid_Programme} > { Language === "en" ? 'Pay and Enroll' : 'الدفع والتسجيل'} </button>

                    }
                    </>
                }


            </div>


        </div>

            
   
    </div>
    </section>
  </div>
  )
}

export default Programme_Page