import React from 'react'

function Footer() {
  return (
    <footer className="footer-section">
    {/*footer top start*/}

    {/* <div className="footer-top gradient-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="row footer-top-wrap">
              <div className="col-12">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">SECURED BY</h4>
                  <ul className="list-inline security-icon-list">
                    <li className="list-inline-item"><img src="/assets/img/mcafee-logo.png" width={110} alt="security" className="img-fluid" /></li>
                    <li className="list-inline-item"><img src="/assets/img/norton-logo.png" width={110} alt="security" className="img-fluid" /></li>
                    <li className="list-inline-item"><img src="/assets/img/accredited-logo.png" width={110} alt="security" className="img-fluid" /></li>
                    <li className="list-inline-item"><img src="/assets/img/secured-logo.png" width={110} alt="security" className="img-fluid" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row footer-top-wrap">
              <div className="col-md-3 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">PRODUCTS</h4>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">WordPress Hosting</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Shared Hosting</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Virtual Private Servers</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Dedicated Servers</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Domain Names</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">COMPANY</h4>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">About</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Affiliates</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Blog</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Careers</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Community</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">LEGAL</h4>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">Legal Information</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Privacy Policy</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Report Abuse</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Terms of Service</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">WHOIS Lookup</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">SUPPORT</h4>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">Contact</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Knowledge Base</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Forums</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">System Status</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Support Team</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    {/*footer top end*/}
    {/*footer copyright start*/}
    <div className="footer-bottom gray-light-bg py-3" style={{ backgroundColor:"#2A343A"}}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-5 col-lg-5">
            <p className="copyright-text pb-0 mb-0">Copyrights © 2021. All
              rights reserved by
              <a href="https://themeforest.net/user/themetags" target="_blank">ThemeTags</a>
            </p>
          </div>
          <div className="col-md-7 col-lg-6">
            <div className="payment-method text-right">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <img src="/assets/img/payment-amex.svg" alt="amex" width={50} />
                </li>
                <li className="list-inline-item">
                  <img src="/assets/img/payment-visa.svg" alt="visa" width={50} />
                </li>
                <li className="list-inline-item">
                  <img src="/assets/img/payment-mastercard.svg" alt="mastercard" width={50} />
                </li>
                <li className="list-inline-item">
                  <img src="/assets/img/payment-paypal.svg" alt="paypal" width={50} />
                </li>
                <li className="list-inline-item">
                  <img src="/assets/img/payment-bitcoin.svg" alt="bitcoin" width={50} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*footer copyright end*/}
  </footer>
  )
}

export default Footer