import React, { useContext, useEffect, useState } from 'react'
import { BaseURL } from '../Constants/baseURL'
import { SimpleContext } from '../Context/ContextData'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

function Home() {

  let navigate = useNavigate()
  const { API_Call , Language } = useContext(SimpleContext)
  const [challenges, setChallenges] = useState([])
  const [programmes, setpProgrammes] = useState([])


  useEffect(() => {
      Get_Challenge()
      Get_Programmes()
  }, [])
  

  const Get_Challenge = async () => {
    let response = await API_Call("get","/challenge_details/all_challenges/",null)
    if (response.status === 200)
    {
      setChallenges(response.data.message)
      console.log(response.data.message)
    }
  }

  const Get_Programmes = async () => {
    let response = await API_Call ("get","/programme_details/all_programmes/",null)
    console.log("Programme Response")
    console.log(response)
    if (response.status === 200)
    {
        setpProgrammes(response.data.message)
    }

  }
  return (
    <div className="main">
    {/*hero section start*/}
    <section className="hero-equal-height ptb-100 gradient-overlay gradient-bg" style={{background: 'url("/assets/img/hellohealth/Banners/banner.png")no-repeat center center / cover'}}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-8 col-12">
            <div className="hero-content-wrap text-white">
              <p className="lead" style={{ color:"#40BBD1" , fontWeight:"bolder"}} > { Language === "en" ? '-- Welcome to Hello Health' : '- مرحبًا بكم في Hello Health'}</p>
              <h2 className="text-black"> { Language === "en" ? 'Reach Your Healthy Numbers' : 'الوصول إلى أرقامك الصحية'}</h2>
              {
                Language === "en" ? 
                <p style={{ color:"gray" }}> Personalized nutrition services to improve your lifestyle based on your health concerns. <br />
                We offer a variety of science-based programs that one of them is potentially the right program for you!  <br />
                I am Racha Sankar PhD MS RD, nutrition scientist, bariatric expert & healthy living advocate </p> :
                 <p style={{ color:"gray" }}> خدمات التغذية الشخصية لتحسين نمط حياتك بناءً على مخاوفك الصحية. <br />
                 نحن نقدم مجموعة متنوعة من البرامج القائمة على العلم والتي من المحتمل أن يكون أحدها هو البرنامج المناسب لك! <br />
                 أنا رشا سانكار ، حاصلة على درجة الدكتوراه ، وعالمة تغذية ، وخبيرة في علاج السمنة ، وداعية للحياة الصحية </p>
              }
             
            </div>
            <a className = "btn primary-solid-btn animated-btn" href={ undefined } onClick={()=>{ return navigate('/Contact' )}}>{ Language === "en" ? 'Contact Us' : 'اتصل بنا'}</a>
            <a className = "btn primary-solid-btn animated-btn" href={ undefined }   onClick={()=>{ return navigate('/About' )}} style={{ margin:"10px"}}>{ Language === "en" ? 'Read More' : 'اقرأ أكثر'}</a>
          </div>

        </div>
      </div>
    </section>
   

    <section className="our-services ptb-100 gray-light-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8">
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
              <h3>{ Language === "en" ? 'The Challenge Of The Month' : 'تحدي الشهر'}</h3>
              {/* <p className="lead">Synergistically architect plug-and-play without next-generation
                manufactured products. Assertively develop synergistic networks.</p> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {
            challenges.length === 0 ? 
            <div className='col-12'>
              <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
              <h4>{ Language === "en" ? 'No Challenge In This Month' : 'لا يوجد تحدي في هذا الشهر'}</h4>
              {/* <p className="lead">Synergistically architect plug-and-play without next-generation
                manufactured products. Assertively develop synergistic networks.</p> */}
            </div>
              </div> 
              :
              <>
              {
                challenges.map((value,key) => (
                  <>
                  {/* <div className="col-md-6 col-lg-4 d-flex" key={key} onClick={()=>{return navigate(`/challenge/${value.id}`)}} style={{ cursor:"pointer"}}>
                  <div className="single-service-plane text-center rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
                    <img src={`${BaseURL}${value.challenge_image}`} alt="shared hosting" className="img-fluid mb-4" width={150}  />
                    <div className="service-plane-content">
                      <h3 className="h5">
                      {Language === "en" ? `${value.challenge_name_english}` : `${value.challenge_name_arabic}` }
                      </h3>
                     
                    </div>
                  
                    
                    <div className="action-wrap mt-3">
                      <p>From: <strong>{moment(value.challenge_from_date).format('MMMM Do  YYYY ')}</strong></p>
                      <p>To: <strong>{moment(value.challenge_to_date).format('MMMM Do  YYYY ')}</strong></p>
                     
                    </div>
                  </div>
                </div> */}


                <div className="col-md-6 col-lg-4" key={key} onClick={()=>{return navigate(`/challenge/${value.id}`)}} style={{ cursor:"pointer"}}>
                <div className="single-service-plane text-center rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
                <img src={`${BaseURL}${value.challenge_image}`} alt="shared hosting" style={{ height:"200px",width:"100%"}} className="img-fluid mb-4" width={150}  />
                  <div className="service-plane-content">
                    <h3 className="h5">
                      {Language === "en" ? `${value.challenge_name_english}` : `${value.challenge_name_arabic}` }
                      </h3>       
                    </div>
                    <div className="action-wrap mt-3">
                      <p>From: <strong>{moment(value.challenge_from_date).format('MMMM Do  YYYY ')}</strong></p>
                      <p>To: <strong>{moment(value.challenge_to_date).format('MMMM Do  YYYY ')}</strong></p>
                     
                    </div>
                </div>
              </div>


                </>
                ))
              }
              </>
          }

        </div>
      </div>
    </section>


    <section className="our-services ptb-100 gray-light-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8">
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
              <h3>{ Language === "en" ? 'The Programmes' : 'البرامج'}</h3>
              {/* <p className="lead">Synergistically architect plug-and-play without next-generation
                manufactured products. Assertively develop synergistic networks.</p> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">

          {

            programmes.length === 0 ?
            <div className='col-12'>
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
            <h4>{ Language === "en" ? 'No Programmes In This Month' : 'لا توجد برامج في هذا الشهر'}</h4>
            {/* <p className="lead">Synergistically architect plug-and-play without next-generation
              manufactured products. Assertively develop synergistic networks.</p> */}
          </div>
            </div>  : 
            <>
            {
              programmes.map((value,key) => (
                <div className="col-md-6 col-lg-4" key={key} onClick={()=>{return navigate(`/programme/${value.id}`)}} style={{ cursor:"pointer"}}>
                <div className="single-service-plane text-center rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
                  <img src={`${BaseURL}${value.programme_image}`} alt="shared hosting" style={{ height:"200px",width:"100%"}} className="img-fluid mb-4" width={130} />
                  <div className="service-plane-content">
                      <h3 className="h5">
                      {Language === "en" ? `${value.programmename_english}` : `${value.programmename_arabic}` }
                      </h3>
                      <h3 className="h5">
                       Fees : { value.programme_price === null || value.programme_price === "" || value.programme_price === "0" || value.programme_price === 0 ? 'Free' : `${ value.programme_price } USD` }
                      </h3>
                      {/* <p>Our most popular product! Shared Web Hosting gives you a fast SSD
                        storage hosting.</p> */}
                    </div>
                  <div className="action-wrap mt-3">
                    {/* <p>Starting at: <strong>$2.59/mo</strong></p> */}
                    {/* <a href="#" className="btn-link">View Details <span className="fas fa-long-arrow-alt-right" /></a> */}
                  </div>
                </div>
              </div>
              ))
            }
            </>

          }

         


        </div>
      </div>
    </section>
   

   
  

  </div>
  )
}

export default Home