
export const Message = ( gender,waist_size,bmi_value ) => {
    if ( gender === "Male")
    {
        if ( waist_size > 102 && bmi_value >= 18.5 || bmi_value <= 24.9 )
        {
            return{
                'en' : "Although your body weight is within the healthy numbers, you need to pay attention to the amount of fat in your abdomen area. An increased waist size can be a marker for an increased health risk in people with normal body weight. You are advised to consult a registered dietitian to help you reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out A Basket of Cherries program, it would be the right program for you. ",
                'ar' : 'على الرغم من أن وزن جسمك ضمن الأرقام الصحية ، إلا أنك تحتاج إلى الانتباه إلى كمية الدهون في منطقة البطن. يمكن أن تكون زيادة حجم الخصر علامة على زيادة المخاطر الصحية لدى الأشخاص ذوي الوزن الطبيعي. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك على تقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج A Basket of Cherries ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 102 && bmi_value >= 25 || bmi_value <= 29.9 )
        {
            return{
                'en': "You are overweight with an increased waist size.  You are at high risk for health problems including types 2 diabetes, hypertension, and cardiovascular disease. You are advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you. ",
                'ar' : 'كنت بدينة مع زيادة حجم الخصر. أنت معرض لخطر كبير للإصابة بمشاكل صحية بما في ذلك مرض السكري من النوع 2 وارتفاع ضغط الدم وأمراض القلب والأوعية الدموية. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 102 && bmi_value >= 30 || bmi_value <= 34.9 )
        {
            return{
                'en' : "You are at level 1 of obesity with an increased waist size. Thus, you are at high risk for types 2 diabetes, hypertension, and cardiovascular disease.If you have a family history of heart disease or diabetes, you are encouraged to consult your physician. You are also advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you. ",
                'ar' : 'كنت في المستوى 1 من السمنة مع زيادة حجم الخصر. وبالتالي ، فأنت معرض لخطر الإصابة بالنوع 2 من مرض السكري وارتفاع ضغط الدم وأمراض القلب والأوعية الدموية. إذا كان لديك تاريخ عائلي من أمراض القلب أو مرض السكري ، فنحن نشجعك على استشارة طبيبك. يُنصح أيضًا باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 102 && bmi_value >= 35 || bmi_value <= 39.9 )
        {
            return{
                'en' :  "You are at level 2 of obesity with an increased waist size. Thus, you are at very high risk for types 2 diabetes, hypertension, and cardiovascular disease.If you have a family history of heart disease or diabetes, you are encouraged to consult your physician. You are also advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you. ",
                'ar' : 'كنت في المستوى 2 من السمنة مع زيادة حجم الخصر. وبالتالي ، فأنت معرض لخطر كبير للإصابة بمرض السكري من النوع 2 ، وارتفاع ضغط الدم ، وأمراض القلب والأوعية الدموية. إذا كان لديك تاريخ عائلي من أمراض القلب أو مرض السكري ، فنحن نشجعك على استشارة طبيبك. يُنصح أيضًا باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 102 && bmi_value >= 40 )
        {
            return{
                'en' : "You are at level of extreme obesity with an increased waist size. Thus, you are at extremely high risk for types 2 diabetes, hypertension, and cardiovascular disease.If you have a family history of heart disease or diabetes, you are encouraged to consult your physician. You are also advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. . We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you.",
                'ar' : 'كنت في مستوى السمنة المفرطة مع زيادة حجم الخصر. وبالتالي ، فأنت معرض لخطر كبير للغاية للإصابة بمرض السكري من النوع 2 ، وارتفاع ضغط الدم ، وأمراض القلب والأوعية الدموية. إذا كان لديك تاريخ عائلي من أمراض القلب أو مرض السكري ، فنحن نشجعك على استشارة طبيبك. يُنصح أيضًا باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. . نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size < 102 && bmi_value >= 18.5 || bmi_value <= 24.9)
        {
            return {
                'en' :  "Your BMI and waist size are within normal range.",
                'ar' :'مؤشر كتلة الجسم وحجم الخصر لديك ضمن النطاق الطبيعي.'
            }
        }
        else {
            return{
                'en' :  `We Cannot Find a message With This Value, For More Info Please Contact ${<a href="https://api.whatsapp.com/send?phone=0019548019957"> Hello Health </a>}` ,
                'ar' : `لا يمكننا العثور على رسالة بقيمة  هذه ، لمزيد من المعلومات ، يرجى الاتصال بـ ${<a href="https://api.whatsapp.com/send؟phone=0019548019957"> Hello Health </a>}`
                
            }
        }
    }
    else if ( gender === "Female")
    {
        if ( waist_size > 88 && bmi_value >= 18.5 || bmi_value <= 24.9 )
        {
            return{
                'en' : "Although your body weight is within the healthy numbers, you need to pay attention to the amount of fat in your abdomen area. An increased waist size can be a marker for an increased health risk in people with normal body weight. You are advised to consult a registered dietitian to help you reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out A Basket of Cherries program, it would be the right program for you.",
                'ar' : 'على الرغم من أن وزن جسمك ضمن الأرقام الصحية ، إلا أنك تحتاج إلى الانتباه إلى كمية الدهون في منطقة البطن. يمكن أن تكون زيادة حجم الخصر علامة على زيادة المخاطر الصحية لدى الأشخاص ذوي الوزن الطبيعي. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك على تقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج A Basket of Cherries ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 88 && bmi_value >= 25 || bmi_value <= 29.9 )
        {
            return { 
                'en' : "You are overweight with an increased waist size.  You are at high risk for health problems including types 2 diabetes, hypertension, and cardiovascular disease. You are advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you. ",
                'ar' : 'كنت بدينة مع زيادة حجم الخصر. أنت معرض لخطر كبير للإصابة بمشاكل صحية بما في ذلك مرض السكري من النوع 2 وارتفاع ضغط الدم وأمراض القلب والأوعية الدموية. يُنصح باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 88 && bmi_value >= 30 || bmi_value <= 34.9 )
        {
            return{
                'en' : "You are at level 1 of obesity with an increased waist size. Thus, you are at high risk for types 2 diabetes, hypertension, and cardiovascular disease.If you have a family history of heart disease or diabetes, you are encouraged to consult your physician. You are also advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you.",
                'ar' : 'كنت في المستوى 1 من السمنة مع زيادة حجم الخصر. وبالتالي ، فأنت معرض لخطر الإصابة بالنوع 2 من مرض السكري وارتفاع ضغط الدم وأمراض القلب والأوعية الدموية. إذا كان لديك تاريخ عائلي من أمراض القلب أو مرض السكري ، فنحن نشجعك على استشارة طبيبك. يُنصح أيضًا باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 88 && bmi_value >= 35 || bmi_value <= 39.9 )
        {
            return{
                'en' : "You are at level 2 of obesity with an increased waist size. Thus, you are at very high risk for types 2 diabetes, hypertension, and cardiovascular disease.If you have a family history of heart disease or diabetes, you are encouraged to consult your physician. You are also advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you. ",
                'ar' : 'كنت في المستوى 2 من السمنة مع زيادة حجم الخصر. وبالتالي ، فأنت معرض لخطر كبير للإصابة بمرض السكري من النوع 2 ، وارتفاع ضغط الدم ، وأمراض القلب والأوعية الدموية. إذا كان لديك تاريخ عائلي من أمراض القلب أو مرض السكري ، فنحن نشجعك على استشارة طبيبك. يُنصح أيضًا باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size > 88 && bmi_value >= 40 )
        {
            return {
                'en' : "You are at level of extreme obesity with an increased waist size. Thus, you are at extremely high risk for types 2 diabetes, hypertension, and cardiovascular disease.If you have a family history of heart disease or diabetes, you are encouraged to consult your physician. You are also advised to consult a registered dietitian to help you manage your body weight and reduce the abdominal fat. . We suggest that you join us in our RS challenges. Also, check out Weigh to Go By The Healthy numbers program, it would be the right program for you.",
                'ar' : 'كنت في مستوى السمنة المفرطة مع زيادة حجم الخصر. وبالتالي ، فأنت معرض لخطر كبير للغاية للإصابة بمرض السكري من النوع 2 ، وارتفاع ضغط الدم ، وأمراض القلب والأوعية الدموية. إذا كان لديك تاريخ عائلي من أمراض القلب أو مرض السكري ، فنحن نشجعك على استشارة طبيبك. يُنصح أيضًا باستشارة اختصاصي تغذية مُسجَّل لمساعدتك في إدارة وزن جسمك وتقليل الدهون في منطقة البطن. . نقترح عليك الانضمام إلينا في تحديات RS. تحقق أيضًا من برنامج Weigh to Go By The Healthy الأرقام ، سيكون البرنامج المناسب لك.'
            }
        }
        else if ( waist_size < 88  && bmi_value >= 18.5 || bmi_value <= 24.9)
        {
            return {
                'en' : "Your BMI and waist size are within normal range.",
                'ar' : 'مؤشر كتلة الجسم وحجم الخصر لديك ضمن النطاق الطبيعي.'
            }
        }
        else {
            return{
                'en' :  `We Cannot Find a message With This  Value, For More Info Please Contact ${<a href="https://api.whatsapp.com/send?phone=0019548019957"> Hello Health </a>}` ,
                'ar' : `لا يمكننا العثور على رسالة بقيمة  هذه ، لمزيد من المعلومات ، يرجى الاتصال بـ ${<a href="https://api.whatsapp.com/send؟phone=0019548019957"> Hello Health </a>}`
                
            }
        }
    }
}