import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseURL } from '../Constants/baseURL'
import { SimpleContext } from '../Context/ContextData'
import { Form, Modal } from 'react-bootstrap'
import axios from 'axios'
import { Show_Toast } from '../Constants/Toast'



function Challenge_Page() {

    const { API_Call , Language , Check_Validation , setValidated , validated , user , authtoken } = useContext(SimpleContext)

    let params = useParams()
    const [promocode_loading, setpromocode_loading] = useState(false)
    const [promocode, setpromocode] = useState("")
    const [activated_coupoun_id, setactivated_coupoun_id] = useState(null)
    const [mix_it_up_price, setmix_it_up_price] = useState(null)
    const [discount_price, setdiscount_price] = useState(null)

    const [level_modal, setlevel_modal] = useState(false)
    const [challenge_option_modal, setchallenge_option_modal] = useState(false)

    const [activity_level, setactivity_level] = useState("Limited Activity")

    let navigate = useNavigate()
    const [challenge_details, setChallenge_details] = useState("")

    const [challenge_type, setchallenge_type] = useState("Tone It Up")

    const [loading, setloading] = useState(false)

    const [is_custom_steps, setis_custom_steps] = useState(false)

    const [custom_steps, setcustom_steps] = useState("")





    useEffect(() => {
        Get_Challenge_Data()
        Check_Enrolled()
    }, [])




    const  datediff = (first, second) => {   
    
        console.log(first)
        console.log(second)
        return Math.round((first - second) / (1000 * 60 * 60 * 24));
    }
    
    const  parseDate = (str) => {
        console.log("---------------------------")
        console.log(str)
        console.log("---------------------------")
        var mdy = str.split('/');
        return new Date(mdy[2], mdy[0] - 1, mdy[1]);
    }
    


    
    const Check_Enrolled = async () => {
        const enrolled_data = localStorage.getItem('enr_id')
        if (enrolled_data)
        {
          var data = {
            "saved_id" : enrolled_data,
            "type" : "Challenge",
          }
          let datas  = await API_Call("post","/enroll/remove_order/",data)
          console.log(datas)
          if (datas.status === 200)
          {
            
            localStorage.removeItem('enr_id')
            return navigate('/error')
    
          }
          else
          {
            return navigate("/")
          }
        }
        else
        {
            Get_Challenge_Data()
        }
  
      }


    const Get_Challenge_Data = async () => {
        var data = {
            "id" : params.id
        }

        let response  = await API_Call("get","/challenge_details/all_challenges",data)
        if (response.status != 200 || response.data.message.length === 0){
            return navigate('/')
        }
        else
        {
            setChallenge_details(response.data.message[0])
        }
    }

    const Add_Promocode = async () => {
        setValidated(true)
        setpromocode_loading(true)
        var data  = {
            "challenge_id" : challenge_details.id,
            "promocode" : promocode
          }
        let response = await API_Call("post","/challenge_details/apply_coupouncode/",data)
        console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++")
        console.log(response)
        setpromocode_loading(false)
        if (response.data.status === 200)
        {
            setactivated_coupoun_id(response.data.challenge_data[0].id)
            var disc_price = ((mix_it_up_price) - ((mix_it_up_price * response.data.challenge_data[0].discount_percentage) / 100 ))
            setdiscount_price(Math.round(disc_price))
            setValidated({validatechallenge : false })
            Show_Toast(response.data.message , true)
            setpromocode("")
        }
        else {
            Show_Toast(response.data.message,false)
        }
    }



    const Enroll_Free_Challenge = async () => {
        if (user === null)
        {
            Show_Toast("Please Login Your Account" , false)
        }
        else
        {
            setloading(true)
            var steps_complete = 0
            if (is_custom_steps)
            {
                if ( challenge_type === "Tone It Up")
                {
                    steps_complete = parseInt(2000) + parseInt(custom_steps)
                }
                else if ( challenge_type === "Size It Down")
                {
                    steps_complete = parseInt(2500) + parseInt(custom_steps)
                }
                else
                {
                    steps_complete = parseInt(2500) + parseInt(custom_steps)
                }
            }
            else
            {
                if ( challenge_type === "Tone It Up" ){
                    if ( activity_level === "Limited Activity")
                    {
                        steps_complete = parseInt(2000) + parseInt( 4500 )
                    }
                    else if ( activity_level === "Low Active")
                    {
                        steps_complete = parseInt(2000) + parseInt( 6000  )
                    }
                    else if ( activity_level === "Moderately Active")
                    {
                        steps_complete = parseInt(2000) + parseInt( 8000  )
                    }
                    else
                    {
                        steps_complete = parseInt(2000) + parseInt( 9500 )
                    }
                }
                else if ( challenge_type === "Size It Down" ){
                    if ( activity_level === "Limited Activity")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 4500 )
                    }
                    else if ( activity_level === "Low Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 6000  )
                    }
                    else if ( activity_level === "Moderately Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 8000  )
                    }
                    else
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 9500 )
                    }
                }
                else {
                    if ( activity_level === "Limited Activity")
                    {
                        steps_complete = parseInt(2500) + parseInt( 4500 )
                    }
                    else if ( activity_level === "Low Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 6000  )
                    }
                    else if ( activity_level === "Moderately Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 8000  )
                    }
                    else
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 9500 )
                    }
                }

            }


            var duration =  datediff(parseDate(`${moment(challenge_details.challenge_to_date).format(
                "MM/DD/YYYY"
              )}`),parseDate(`${moment(challenge_details.challenge_from_date).format(
                "MM/DD/YYYY"
              )}`)) 


            var data = {
              "user_id" : user.user_id,
              "challenge_id" : challenge_details.id,
              "is_free" : true,
              "challenge_type" : challenge_type,    
              "is_custom_steps" : is_custom_steps,
              "activity_level" : activity_level,
              "custom_steps" : custom_steps,
              "paid_status" : "Free of Cost",
            //   "steps_to_complete" : challenge_details.no_of_steps_to_complete ,
              "steps_to_complete" :  parseFloat(steps_complete) * parseFloat ( duration ) ,
              "steps_completed" : 0 ,
            }

         
            

            
            let response = await API_Call("post","/enroll/enroll_free_challenge/",data)
            setloading(false)
            if (response.status === 200)
            {
             
              return navigate('/success')
            }
            else
            {
              Show_Toast(response.message , false)
            }
           
        }
            
          
      }




      

      const Check_Custom = (e) => {
        console.log(e.target.checked)
        if (e.target.checked === true)
        {
            setis_custom_steps( true )
            setactivity_level("")
        }
        else
        {
            setis_custom_steps( false )
            setcustom_steps( null )
            setactivity_level("Limited Activity")
        }
      }


      const Enroll_Paid_Challenge  = async () => {
        if (user === null)
        {
            Show_Toast("Please Login Your Account" , false)
        }
        else
        {   
            setloading(true)
            var steps_complete = 0
            if (is_custom_steps)
            {
                if ( challenge_type === "Tone It Up")
                {
                    steps_complete = parseInt(2000) + parseInt(custom_steps)
                }
                else if ( challenge_type === "Size It Down")
                {
                    steps_complete = parseInt(2500) + parseInt(custom_steps)
                }
                else
                {
                    steps_complete = parseInt(2500) + parseInt(custom_steps)
                }
            }
            else
            {
                if ( challenge_type === "Tone It Up" ){
                    if ( activity_level === "Limited Activity")
                    {
                        steps_complete = parseInt(2000) + parseInt( 4500 )
                    }
                    else if ( activity_level === "Low Active")
                    {
                        steps_complete = parseInt(2000) + parseInt( 6000  )
                    }
                    else if ( activity_level === "Moderately Active")
                    {
                        steps_complete = parseInt(2000) + parseInt( 8000  )
                    }
                    else
                    {
                        steps_complete = parseInt(2000) + parseInt( 9500 )
                    }
                }
                else if ( challenge_type === "Size It Down" ){
                    if ( activity_level === "Limited Activity")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 4500 )
                    }
                    else if ( activity_level === "Low Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 6000  )
                    }
                    else if ( activity_level === "Moderately Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 8000  )
                    }
                    else
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 9500 )
                    }
                }
                else {
                    if ( activity_level === "Limited Activity")
                    {
                        steps_complete = parseInt(2500) + parseInt( 4500 )
                    }
                    else if ( activity_level === "Low Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 6000  )
                    }
                    else if ( activity_level === "Moderately Active")
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 8000  )
                    }
                    else
                    {
                        steps_complete = parseInt(2500 ) + parseInt( 9500 )
                    }
                }

            }


            var duration =  datediff(parseDate(`${moment(challenge_details.challenge_to_date).format(
                "MM/DD/YYYY"
              )}`),parseDate(`${moment(challenge_details.challenge_from_date).format(
                "MM/DD/YYYY"
              )}`)) 



            var data = {
              "user_id" : user.user_id,
              "challenge_id" : challenge_details.id,
              "is_free" : false,
              "challenge_type" : challenge_type,
            //   "steps_to_complete" : challenge_details.no_of_steps_to_complete ,
              "steps_to_complete" : parseFloat(steps_complete) * parseFloat ( duration ) ,
              "steps_completed" : 0 ,
              "is_custom_steps" : is_custom_steps,
              "activity_level" : activity_level,
              "custom_steps" : custom_steps,
              "paid_status" : "UnPaid",
              "payable_amount" :  `${discount_price === null ? mix_it_up_price : discount_price } USD`,
              "discount_price" : discount_price,
              "challenge_price" : mix_it_up_price,
              "coupouncode_details" : activated_coupoun_id
      
            }
            let response = await API_Call("post","/enroll/enroll_paid_challenge/",data)
            
           
            if (response.status === 200)
            {
               
              localStorage.setItem('enr_id',response.saved_id)
              Payment_Function(response.data.saved_id)
      
            }
            else
            {
                Show_Toast(response.message , false)
                setloading(false)
            }

            console.log(data)
        }
      
        
      }

      const Payment_Function = async (enroll_id) => {
        setloading(true)
        var data = {
          "name" : challenge_type + "Challenge",
          "price" : `${discount_price === null ? mix_it_up_price * 100 : discount_price * 100 }`,
          "origin_site" : window.location.origin,
          "enroll_id" : enroll_id,
          "type" : "Challenge"
        }
        await axios.post(`${BaseURL}/enroll/create-checkout-session/`,data,{
          headers : {
            Authorization :  `Bearer ${authtoken.access}`
          }
        })
        .then((res) => {    
            setloading(false)
            window.location.assign(res.data.message.url); 
        })
        .catch((error) => {
            Show_Toast("Something Went Wrong" , false)
        })
      }

  return (
  <div className="main">
    <section className="our-team-section ptb-100 gray-light-bg" style={{ paddingTop:"90px"}}>
    <div className="container">
        <div className='row'>
        <div className='col-12 col-md-6'>
            <img src={`${BaseURL}${challenge_details.challenge_image}`} alt="team image" className="card-img-top" style={{ maxHeight:"350px"}} />
        </div>
        <div className='col-12 col-md-6'>
        <div class="features-box p-4">
         
            <div class="features-box-content">
                <h3>
                    {Language === "en" ? `${challenge_details.challenge_name_english}` : `${challenge_details.challenge_name_arabic}` }

                </h3>
                <h5>{ Language === "en" ? 'Period' : 'فترة'} :</h5>
                <h6> {Language === "en" ? 'From Date  :' : 'من التاريخ : '} {moment(challenge_details.challenge_from_date).format('MMMM Do  YYYY ')}</h6>
                <h6>{Language === "en" ? 'To Date  :' : 'ان يذهب في موعد :'} {moment(challenge_details.challenge_from_date).format('MMMM Do  YYYY ')}</h6>
            </div>
        </div>
        </div>
        </div>

        <div className='row mt-4' style={{ marginTop:"30px"}}>
            <div className='col-12 col-md-6' style={{ marginTop:"40px"}}>
                <h6>{ Language === "en" ? 'Challenge Options' : 'خيارات التحدي'}  <li className="ml-3 list-inline-item"><span className="fas fa-solid fa-circle-info mr-2" onClick={()=>setchallenge_option_modal(true)} /></li></h6> 
                <div className="text-center mb-5 mt-4 radio-box-wrap billingCycle">
                    <div className="single-radio-box" onClick={()=>{setchallenge_type("Tone It Up");setmix_it_up_price(null)}}>
                        <input name="challengeoption" id="monthly-plan" defaultValue="monthly" className="radio" type="radio" defaultChecked />
                        <label htmlFor="monthly-plan"><span className="custom-check" />Ton It Up</label>
                    </div>
               
                    <div className="single-radio-box" onClick={()=>{setchallenge_type("Size It Down");setmix_it_up_price(null) }}>
                        <input name="challengeoption" id="biannual-plan" defaultValue="biannual" className="radio" type="radio" />
                        <label htmlFor="biannual-plan"><span className="custom-check" /> Size It Down</label>
                    </div>
                    <div className="single-radio-box" onClick={()=>{setchallenge_type("Mix it Up");setmix_it_up_price("100")}}>
                        <input name="challengeoption" id="triennial-plan" defaultValue="triennial" className="radio" type="radio" />
                        <label htmlFor="triennial-plan"><span className="custom-check" /> Mix it Up </label>
                    </div>
                </div>
            </div>

            <div className='col-12 col-md-6' style={{ marginTop:"40px"}}>
                <h6>{ Language === "en" ? 'Physical Activity Level' : 'مستوى النشاط البدني'} <li className="ml-3 list-inline-item"><span className="fas fa-solid fa-circle-info mr-2" onClick={()=>setlevel_modal(true)} /></li></h6>
                {
                    is_custom_steps ? 
                    <Form className="login-signup-form">
                    <div className="form-group">
                        <label> { Language === "en" ? 'Custom Steps' : 'خطوات مخصصة'} </label>
                        <input  required type="number" className="form-control input" id="email-footer" placeholder={ Language === "en" ? 'Enter Your Custom Steps' : 'أدخل خطواتك المخصصة'} value={custom_steps} onChange = {(e)=>setcustom_steps(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                           { Language === "en" ? ' Please Provide a Coupoun Code' : 'يرجى تقديم رمز القسيمة'}
                        </Form.Control.Feedback>
                    </div>
                </Form> :
                         <div className="text-center mb-2 mt-4 radio-box-wrap billingCycle">
                         <div className="single-radio-box">
                             <input name="activitylevel" id="limited-activity" defaultValue="limited" className="radio" type="radio" checked = { activity_level === "Limited Activity" ? true : false } onClick={()=>setactivity_level("Limited Activity")} />
                             <label htmlFor="limited-activity"><span className="custom-check" />{ Language === "en" ? 'Limited Activity' : 'نشاط محدود'}</label>
                         </div>
                    
                         <div className="single-radio-box">
                             <input name="activitylevel" id="low-active" defaultValue="low" className="radio" type="radio" checked = { activity_level === "Low Active" ? true : false } onClick={()=>setactivity_level("Low Active")} />
                             <label htmlFor="low-active"><span className="custom-check" /> { Language === "en" ? 'Low Active' : 'منخفض النشاط'} </label>
                         </div>
                         <div className="single-radio-box">
                             <input name="activitylevel" id="moderately-active" defaultValue="moderately" className="radio" type="radio" checked = { activity_level === "Moderately Active" ? true : false } onClick={()=>setactivity_level("Moderately Active")} />
                             <label htmlFor="moderately-active"><span className="custom-check" /> { Language === "en" ? 'Moderately Active' : 'نشط بشكل معتدل'} </label>
                         </div>
     
                         <div className="single-radio-box">
                             <input name="activitylevel" id="active" defaultValue="acti" className="radio" type="radio" checked = { activity_level === "Active" ? true : false } onClick={()=>setactivity_level("Active")} />
                             <label htmlFor="active"><span className="custom-check" /> { Language === 'en' ? 'Active' : 'نشيط'} </label>
                         </div>
     
                         {/* <div className="single-radio-box">
                             <input name="activitylevel" id="highly-active" defaultValue="highly" className="radio" type="radio"  checked = { activity_level === "Highly Active" ? true : false } onClick={()=>setactivity_level("Highly Active")} />
                             <label htmlFor="highly-active"><span className="custom-check" /> Highly Active </label>
                         </div> */}

                         <div className="single-radio-box">
                             <input name="activitylevel" id="highly-active" defaultValue="highly" className="radio" type="radio"   onClick={(e)=>setis_custom_steps(true)&setactivity_level("")} />
                             <label htmlFor="highly-active"><span className="custom-check" /> { Language === "en" ? 'Highly Active' : 'نشط للغاية'} </label>
                         </div>
                     </div>
                }
               

                

                <b><input type="checkbox" onChange={(e)=>Check_Custom(e)} checked = { is_custom_steps ? true : false } defaultValue id="customactivityinput" style={{ marginRight : "5px" }}  />{ Language === "en" ? 'Add Custom Steps' : 'أضف خطوات مخصصة'}</b>
                
            </div>
        </div>
        <div className='row mt-2 mt-4'>
          
            <div className='col-12 col-md-6'>
            {
                challenge_type === "Mix it Up" ? 
                <>
                    <h6>{ Language === "en" ? 'Apply Coupun Code' : 'تطبيق كود الكوبون'} <li className="ml-3 list-inline-item"><span className="fas fa-solid fa-circle-info mr-2" /></li></h6>
                    <Form className="d-md-block d-lg-block mt-2" id = "validatechallenge"  noValidate validated={validated.validatechallenge} onSubmit={(event)=>Check_Validation(event,Add_Promocode)}>
                        <input value={promocode} required onChange={(e)=>setpromocode(e.target.value)} type="text" className="form-control input" id="email-footer" name="email" placeholder={ Language === "en" ? 'Enter Your Coupoun code' : 'أدخل رمز القسيمة الخاص بك'} />
                        <Form.Control.Feedback type="invalid">
                            { Language === "en" ? 'Please Provide a Coupoun Code' : 'يرجى تقديم رمز القسيمة'}
                        </Form.Control.Feedback>
                        {
                            promocode_loading ? 
                        <button disabled={true} className="btn primary-solid-btn btn-block btn-not-rounded mt-3">{ Language === "en" ? 'Loading ...' : 'تحميل ...'}</button>
                            :
                        <button type="submit" className="btn primary-solid-btn btn-block btn-not-rounded mt-3">{ Language === "en" ? 'Submit' : ''}</button>

                        }
                    </Form>
                </>
                :''
            }
         

            </div>
            <div className='col-12 col-md-6' style={{ marginTop:"40px"}}>
            <h6>{ Language === "en" ? 'Pricing Details' : 'تفاصيل التسعير'}</h6>

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colSpan={2} style={{ textAlign:"center"}}>{ Language === "en" ? 'Pricing Details' : 'تفاصيل التسعير'}</th>
                    </tr>
                </thead>
                {
                    challenge_type != "Mix it Up" ? 
                    <tbody>
                        <tr>
                            <th>{ Language === "en" ? 'Total Price' : 'السعر الكلي'} </th>
                            <th> { Language === "en" ? 'Free' : 'حر'} </th> 
                        </tr>
                    </tbody>
                    :
                    <tbody>
                    <tr>
                        <th> { Language === "en" ? 'Total Price' : 'السعر الكلي'} </th>
                        <th> { mix_it_up_price } </th> 
                    </tr>
                    <tr>
                        <th> { Language === "en" ? 'Discount Price' : 'سعر الخصم'} </th>
                        <th> { discount_price === null ? `${ Language === "en" ? 'No Disount Added' : 'لا يوجد خصم مضاف'}` : discount_price} </th> 
                    </tr>
                    <tr>
                        <th> {Language === "en" ? 'Total' : 'المجموع'} </th>
                        <th> { discount_price === null ? mix_it_up_price : discount_price} </th> 
                    </tr>
                   
                </tbody>

                }
               
                </table>
                {
                    challenge_type === "Mix it Up" ?
                    <>
                    {
                        loading ? 
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" disabled={true}> { Language === "en" ? 'Loading ...' : 'تحميل ...'} </button>
                        :
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" onClick={Enroll_Paid_Challenge}> { Language === "en" ? 'Pay and Enroll' : 'الدفع والتسجيل'} </button>

                    }
                    </>
                    :
                    <>
                    {
                        loading ? 
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" disabled = { true }> { Language === "en" ? 'Loading ...' : 'تحميل ...'} </button>
                        :
                    <button  className="btn primary-solid-btn btn-block btn-not-rounded mt-3" onClick={Enroll_Free_Challenge}> { Language === "en" ? 'Enroll Now' : 'تسجيل الآن'} </button>

                    }
                    </>
                }


            </div>
        </div>


        {/* <div className='row mt-4 justify-content-center'>
            <a href="#" class="btn primary-solid-btn mb-3" target="_blank">Enroll Now</a>
        </div>  */}
            
   
    </div>
    </section>

    <Modal
        show={level_modal}
        onHide={() => setlevel_modal(false)}
        // backdrop="static"
        size="md"
        centered
        keyboard={false}
      >
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
                Activity Level
            </h5>
            <li className="list-inline-item" >
              <span className="fas fa-solid fa-xmark mr-2" onClick={()=>setlevel_modal(false)} />
            </li>
          </div> */}
          <div className="modal-body">
          <table class="table table-borderless">
                <thead>
                    <th> { Language === "en" ? 'Activity Type' : 'نوع النشاط'}</th>
                    <th> { Language === "en" ? 'Range' : 'يتراوح'} </th>
                </thead>
                <tbody>
                   <tr>
                        <th>{ Language === "en" ? 'Limited activity' : 'نشاط محدود'} </th>
                        <th>5000 or less </th>
                   </tr>
                   <tr>
                        <th> { Language === "en" ? 'Low active' : 'منخفض النشاط'} </th>
                        <th>5001-6999 </th>
                   </tr>
                   <tr>
                        <th>{ Language === "en" ? 'Moderately active' : 'نشط بشكل معتدل'} </th>
                        <th>7000-8999 </th>
                   </tr>
                   <tr>
                        <th>{ Language === "en" ? 'Active' : 'نشيط'}</th>
                        <th>9000-10000 </th>
                   </tr>
                   <tr>
                        <th> { Language === "en" ? 'Highly active' : 'نشط للغاية'} </th>
                        <th>10001 and above </th>
                   </tr>
                </tbody>
                </table>
          </div>
          <hr />
        </div>
      </Modal>


      <Modal
        show={challenge_option_modal}
        onHide={() => setchallenge_option_modal(false)}
        // backdrop="static"
        size="lg"
        centered
        keyboard={false}
      >
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
                Activity Level
            </h5>
            <li className="list-inline-item" >
              <span className="fas fa-solid fa-xmark mr-2" onClick={()=>setlevel_modal(false)} />
            </li>
          </div> */}
          <div className="modal-body">
                {
                    Language === "en" ? 
                    <>
                        1.	<b style={{ color:"black"}}>Tone it up : </b><p className='nav-link custom-nav-link'> Improve your fitness level. Increase your muscle strength. Improve your balance. Free access to step log sheet.</p> 
                        2.	<b style={{ color:"black"}}>Size it Down : </b><p className='nav-link custom-nav-link'> Change body composition. Experience lean mass gain and some fat loss, No change body weight. Free access to step log sheet.</p> 
                        3.	<b style={{ color:"black"}}>Mix it up : </b><p className='nav-link custom-nav-link'> Improve your fitness level. Change your body composition. Improve your dietary habits. You will be provided with dietary guidance and weekly feedback on your progress. Recommended for weight loss and/or diabetes. Personal account, step log sheet, personalized weight log sheet. </p> 
                       
                    </> :
                     <>
                        ١.	<b style={{ color:"black"}}> قوة و همة : </b><p className='nav-link custom-nav-link'>لزيادة اللياقة و قوة العضل و تحسين توازن الجسم. دخول مجاني لقائمة متابعة الخطوات.  </p> 
                        ٢.	<b style={{ color:"black"}}> مقاسك أصغ : </b><p className='nav-link custom-nav-link'>: تغير في مكونات الجسم, زيادة الكتلة العضلية, نقصان في الشحوم, لا تغيير في الوزن. دخول مجاني لقائمة متابعة الخطوات.</p> 
                        ٣.	<b style={{ color:"black"}}> أكلك أفضل : </b><p className='nav-link custom-nav-link'>: لزيادة اللياقة, تغير مكونات الجسم, تحسين السلوكيات الغذائية. يصلك شرح وافي لإحتياج جسمك من السعرات, دليل غذائي , و ارشادات غذائية تناسب جسمك. متابعة أسبوعية عن طريق البريد الإلكتروني. يُنصح به لنقصان الوزن أو داء السكري. حساب شخصي, قائمة متابعة الخطوات و قائمة شخصية لمتابعة الوزن.</p> 
                       
                    </> 
                } 
          </div>
          <hr />
        </div>
      </Modal>


  </div>

  )
}

export default Challenge_Page