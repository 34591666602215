import React from 'react'
import Header from '../Components/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'
import Auth_Modal from '../Modal/Auth_Modal'

function Outlet_Connection() {
  return (
    <div>
        <Auth_Modal />
        <Header />
        <Outlet />
        {/* <Footer /> */}
        <button className="scroll-top scroll-to-target" data-target="html">
            <span className="ti-rocket" />
        </button>
    </div>
  )
}

export default Outlet_Connection