import React, { useContext, useEffect, useState } from 'react'
import { SimpleContext } from '../Context/ContextData'
import moment from 'moment'

function My_Programmes() {
  const { API_Call , Language } = useContext(SimpleContext)
  const [enrolled_programmes, setenrolled_programmes] = useState([])

  useEffect(() => {
    Get_Enrolled_Programmes()
  }, [])

  const Get_Enrolled_Programmes = async () => {
    let response = await API_Call("get","/enroll/user_enrolled_programmes/",null)
    if (response.status === 200)
    {
      setenrolled_programmes(response.data)
      console.log(response.data)
    }

  }
  
  return (
   
    <div className="main">
   
   
    <section className="our-services ptb-100 gray-light-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8">
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
              <h5>{ Language === "en" ? 'Programme List' : 'قائمة البرامج'}</h5>
              {/* <p className="lead">Synergistically architect plug-and-play without next-generation
                manufactured products. Assertively develop synergistic networks.</p> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {
            enrolled_programmes.length === 0 ? 
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
            <h6> { Language === "en" ? 'No Enrolled Programmes' : 'لا توجد برامج مسجلة'} </h6>
            {/* <p className="lead">Synergistically architect plug-and-play without next-generation
              manufactured products. Assertively develop synergistic networks.</p> */}
          </div>:
          <>
          {enrolled_programmes.map((value,key) => (
              <div className="col-md-6 col-lg-4" key={key} style = {{ cursor : "pointer"}}>
              <div className="single-service-plane text-center rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
                <div className="service-plane-content">
                  <h3 className="h5">{ Language === "en" ? value.programme_details[0].programmename_english : value.programme_details[0].programmename_arabic}</h3>
                  <h6> Start Date  :  {moment(value.created_date).format('MMMM Do  YYYY ')} </h6>
                  {/* <h6> Target  : 565672</h6>
                  <h6> Total Steps so far  : 737443 </h6>
                  <h6> % of target Completion  : 56 %</h6> */}
                </div>
                {/* <div className="action-wrap mt-3">
                  <p>Starting at: <strong>$2.59/mo</strong></p>
                  <a href="#" className="btn-link">View Details <span className="fas fa-long-arrow-alt-right" /></a>
                </div> */}
              </div>
            </div>
          ))}
          </>
          }

        
   
        </div>
      </div>
    </section>

  </div>

  )
}

export default My_Programmes