import axios from "axios";
import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../Constants/baseURL";
import { Show_Toast } from "../Constants/Toast";
import { SimpleContext } from "../Context/ContextData";

function Change_Password() {

  let navigate = useNavigate()

  const { Check_Validation ,validated ,
    setValidated } = useContext(SimpleContext);
  const params = useParams();

  const [loading, setloading] = useState(false);

  const [password, setpassword] = useState("");
  const [repassword, setrepassword] = useState("");


   
  const Reset_Password = async () => {
    setloading(true)
    var datas = {
        'password' : password
    }
    await axios.patch(`${BaseURL}/user_details/password-reset/${params.str}/${params.id}/`,datas)
    .then((res) => {
        if (res.data.status === 200)
        {
            setloading(false)
            Show_Toast(res.data.message, true )
            setpassword("")
            setrepassword("")
            return navigate('/')
            
        }
        else
        {
            setloading(false)
            Show_Toast(res.data.message, false )

            
        }
    })
    .catch((error) => {
        Show_Toast("Something Went Wrong", false )
        setloading(false)
    })
  }

  const Back_To_Home = () => {
    window.location.replace("/")
  }


  return (
    <div className="main">
      <section className="our-services ptb-100 gray-light-bg">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-4">
              <div className="contact-us-form  rounded p-1">
                <Form
                    validated={validated.resetpass}
                    id = "resetpass"

                    className="login-signup-form"
                    noValidate

                   onSubmit={(event)=>Check_Validation(event,Reset_Password)}
                >
                  <div className="row">
                    <h4 style={{ margin: "auto" }}>Reset Password</h4>

                    <div className="col-12">
                      <div className="form-group">
                        <label>Password</label>

                        <input
                          type="text"
                          className="form-control"
                          required
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                        />

                        <Form.Control.Feedback type="invalid">
                          Provide a valid Name
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label>Re Enter Password</label>

                        <input
                          type="text"
                          className="form-control"
                          
                          required
                          value={repassword}
                          onChange={(e) => setrepassword(e.target.value)}
                        />

                        <Form.Control.Feedback type="invalid">
                          Provide a valid Name
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-12" style={{ textAlign: "right" }}>
                      {/* {
                          loading ? 
                          <button disabled = { true } className="btn primary-solid-btn" id="btnContactUs">
                        Loading 
                      </button>:  */}
                      <button
                        type="submit"
                        className="btn primary-solid-btn"
                        id="btnContactUs"
                      >
                        Save
                      </button>
                      {/* } */}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Change_Password;
