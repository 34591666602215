import React, { useContext, useEffect, useState } from 'react'
import { SimpleContext } from '../Context/ContextData'
import moment from 'moment'
import { Form, Modal } from 'react-bootstrap'
import { Show_Toast } from '../Constants/Toast'
import axios from 'axios'
import { BaseURL } from '../Constants/baseURL'

function My_Challenges() {


  const  {  API_Call , Language , Check_Validation , validated , setValidated  } = useContext(SimpleContext)

  const [enrolled_challenge, setenrolled_challenge] = useState([])

  const [activity_date, setactivity_date] = useState("")
  const [steps, setsteps] = useState("")

  const [is_previous_steps, setis_previous_steps] = useState(false)
  const [previous_steps, setprevious_steps] = useState([])

  const [loading, setloading] = useState(false)

  const [update_step_count_modal, setupdate_step_count_modal] = useState({
    show : false,
    count : '',
    id : '',
    enroll_id : '',
  })

  const [add_step_modal, setadd_step_modal] = useState({
    show : false,
    from : "",
    to : '',
    id : ''
  })

  useEffect(() => {
    Get_Enrolled_Challenges()
  }, [])
  

  const Get_Enrolled_Challenges = async () => {

    let response  = await API_Call("get","/enroll/user_enrolled_challenge/",null)
    
    if (response.status === 200)
    {
      setenrolled_challenge(response.data)
      console.log(response.data)
    }
  }

  const Update_Steps = async () => {
    setloading(true)
    var data = {
      "steps" : steps,
      "enroll_id" : add_step_modal.id,
      "added_date" : activity_date
    }

    console.log(data)

    let response = await API_Call("post","/enroll/update_user_steps/",data)
    setloading(false)
    console.log(response.data)
    if (response.data.status === 200)
    {
      
      setadd_step_modal({ show : false , from  : '' , to : '' , id : ''})
      Get_Enrolled_Challenges()
      setactivity_date("")
      setsteps("")
      setValidated({ addsteps : false })
      Show_Toast(response.data.message,true)

    }
    else {
      Show_Toast(response.data.message , false)
    }
  }

  const Get_Previous_Data = (id) => {
    axios.get(`${BaseURL}/enroll/get-previous-data`,{
      params : {
        id : id
      }
    }).then((res) => {
      console.log(res.data)
      setprevious_steps(res.data.message)
    })
    .catch((error) =>{
      Show_Toast("Something Went Wrong",true)
    })
  }


  const Delete_Previous_Step = (id,enroll_id) => {
    axios.get(`${BaseURL}/enroll/delete-previous-data`,{
      params : {
        id : id,
        enroll_id : enroll_id
      }
    })
    .then((res) => {
      Show_Toast(res.data.message,true)
      Get_Previous_Data(enroll_id)
      Get_Enrolled_Challenges()
    })
    .catch((error) =>{
      Show_Toast("Something Went Wrong",true)

    })
  }

  const Edit_Previous_Step = () => {
    var data = {
      'count' : update_step_count_modal.count,
      'enroll_id' : update_step_count_modal.enroll_id,
      'id' : update_step_count_modal.id
    }
    axios.post(`${BaseURL}/enroll/update-previous-step/`,data)
    .then((res) => {
      Show_Toast(res.data.message , true )
      setupdate_step_count_modal({show : false,count : '',id : '',enroll_id : ''})
      Get_Previous_Data(update_step_count_modal.enroll_id)
      Get_Enrolled_Challenges()
    })
    .catch((error) =>{
      Show_Toast("Something Went Wrong",true)
    })
  } 

  return (
  <div className="main">
   
   
    <section className="our-services ptb-100 gray-light-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-8">
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
              <h5>{ Language === "en" ? 'Challenge List' : 'قائمة التحدي'}</h5>
              {/* <p className="lead">Synergistically architect plug-and-play without next-generation
                manufactured products. Assertively develop synergistic networks.</p> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {
            enrolled_challenge.length === 0 ? 
            <div className="section-heading text-center mb-5 mb-sm-5 mb-md-3 mb-lg-3">
            <h6> { Language === "en" ? 'No Enrolled Challenge' : 'لا يوجد تحدي مسجل'} </h6>
            {/* <p className="lead">Synergistically architect plug-and-play without next-generation
              manufactured products. Assertively develop synergistic networks.</p> */}
          </div>:
          <>
          {enrolled_challenge.map((value,key) => (
              // <div className="col-md-6 col-lg-4" key={key} style={{ cursor:"pointer"}} onClick={(e)=>setadd_step_modal({ show : true , from : `${moment(value.challenge_details[0].challenge_from_date).format('MMMM Do  YYYY ')}` , to : `${moment(value.challenge_details[0].challenge_to_date).format('MMMM Do  YYYY ')}` , id : value.id })}>
              <div className="col-md-6 col-lg-4" key={key} style={{ cursor:"pointer"}} onClick={(e)=>Get_Previous_Data(value.id) & setadd_step_modal({ show : true , from : `${moment(value.challenge_details[0].challenge_from_date).format('MMMM Do  YYYY ')}` , to : `${moment(value.challenge_details[0].challenge_to_date).format('MMMM Do  YYYY ')}` , id : value.id })}>
              <div className="single-service-plane text-center rounded white-bg shadow-sm p-5 mt-md-4 mt-lg-4">
                <div className="service-plane-content">
                  <h3 className="h5">{Language === "en" ? `${value.challenge_details[0].challenge_name_english}` : `${value.challenge_details[0].challenge_name_arabic}` }</h3>
                  <h6> Start Date  : {moment(value.created_date).format('MMMM Do  YYYY ')} </h6>
                  <h6> Target  : { value.steps_to_complete }</h6>
                  <h6> Total Steps so far  : { value.steps_completed } </h6>
                  <h6> % of target Completion  : { Math.round(((value.steps_completed) / (value.steps_to_complete)) * 100) } % </h6>
                </div>
                {/* <div className="action-wrap mt-3">
                  <p>Starting at: <strong>$2.59/mo</strong></p>
                  <a href="#" className="btn-link">View Details <span className="fas fa-long-arrow-alt-right" /></a>
                </div> */}
              </div>
            </div>
          ))}
          </>
          }

        
   
        </div>
      </div>
    </section>




    <Modal
        show={add_step_modal.show}
        onHide={()=>setadd_step_modal({ show : false , from  : '' , to : '' , id : ''}) & setis_previous_steps(false) & setprevious_steps([])}
        backdrop="static"
        size="md"
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Add Steps
            </h5>
            <li
              className="list-inline-item"
              onClick={()=>setadd_step_modal({ show : false , from  : '' , to : '' , id : ''}) & setis_previous_steps(false) & setprevious_steps([])}
            >
              <span className="fas fa-solid fa-xmark mr-2" />
            </li>
          </div>
          <div className="modal-body">
            {
              is_previous_steps ? 
              <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Steps</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  previous_steps.length === 0 ?
                  <tr><td colSpan={3} style={{ textAlign:"center"}}> No Previous Steps Added</td></tr>
                  :
                  <>
                  {
                    previous_steps.map((value,key) => (
                      <tr key={key}>

                        <th scope="row" >{ value.added_date }</th>
                        <td>{ value.steps }</td>
                        <td>
                          <button className='btn btn-warning' onClick={()=>setupdate_step_count_modal({...update_step_count_modal , show : true , id : value.id , count :  value.steps , enroll_id : add_step_modal.id })}> <i class="fas fa-solid fa-pen-to-square"></i> </button>
                          <button className='btn btn-danger' onClick={()=>Delete_Previous_Step(value.id,add_step_modal.id)} style={{ marginLeft:"5px"}}> <i class="fas fa-solid fa-trash"></i> </button>
                        </td>
    
                      </tr>
                    ))
                  }
                  </>
                }
                
            
              </tbody>
            </table> :
                 <Form
                 noValidate
                 validated={validated.addsteps}
                 className="login-signup-form"
                 id  = "addsteps"
                 //   onSubmit = {(event) => { id === "" ? Check_Validation(event,Create_Client) : Check_Validation(event,Update_Client) }}
                 onSubmit={(event) => Check_Validation(event, Update_Steps)}
               >
                 <h6>Challenge Name</h6>
                 <div className="form-group">
                   <label>From Date : <b> {add_step_modal.from} </b></label> <br />
                   <label>To Date : <b> {add_step_modal.to} </b></label>
                 </div>
   
                 <div className="form-group">
                   <label className="pb-1">Activity Date</label>
   
                     <input
                       type="date"
                       className="form-control"
                       required
                       value = { activity_date }
                       onChange = {(e)=>setactivity_date(e.target.value)}
                      
                     />
                     <Form.Control.Feedback type="invalid">
                       Provide a Date
                     </Form.Control.Feedback>
                 </div>
   
                 
                 <div className="form-group">
                   <label className="pb-1">Total Steps</label>
                   
                     <input
                       type="text"
                       className="form-control"
                       required
                       value = { steps }
                       onChange = {(e)=>setsteps(e.target.value)}
                      
                     />
                     <Form.Control.Feedback type="invalid">
                       Provide a Steps
                     </Form.Control.Feedback>
                 </div>
                 {
                   loading ? 
                   <button
                     className="btn primary-solid-btn border-radius"
                     style={{ width: "100%" }}
                     disabled = { true }
                   >
                     Loading ...
                   </button> :
                   <button
                   className="btn primary-solid-btn border-radius"
                   style={{ width: "100%" }}
                 >
                   Submit
                 </button>
                 }
                 {/* <button
                     className="btn primary-solid-btn border-radius"
                     style={{ width: "100%" }}
                   >
                     Submit
                   </button> */}
               </Form>
            }
           
            <b><input type="checkbox" className='mt-4' defaultValue onChange={()=>setis_previous_steps(!is_previous_steps)} id="customactivityinput" style={{ marginRight : "5px" }}  />Previous Steps</b>

        
          </div>
        </div>
      </Modal>















      <Modal
        show={update_step_count_modal.show}
        onHide = {()=>setupdate_step_count_modal({ show : false })}
        backdrop="static"
        size="sm"
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Update Step
            </h5>
            <li
              className="list-inline-item"
              onClick={()=>setadd_step_modal({ show : false , from  : '' , to : '' , id : ''}) & setis_previous_steps(false) & setprevious_steps([])}
            >
              <span className="fas fa-solid fa-xmark mr-2" />
            </li>
          </div>
          <div className="modal-body">
           
                 <Form
                 noValidate
                 validated={validated.updatesteps}
                 className="login-signup-form"
                 id  = "updatesteps"
                 //   onSubmit = {(event) => { id === "" ? Check_Validation(event,Create_Client) : Check_Validation(event,Update_Client) }}
                 onSubmit={(event) => Check_Validation(event, Edit_Previous_Step)}
               >
                
                 <div className="form-group">
                   <label className="pb-1">Steps Count</label>
   
                     <input
                       type="text"
                       className="form-control"
                       required
                       value = { update_step_count_modal.count }
                       onChange = {(e)=>setupdate_step_count_modal({ ... update_step_count_modal , count : e.target.value })}
                      
                     />
                     <Form.Control.Feedback type="invalid">
                       Provide a Date
                     </Form.Control.Feedback>
                 </div>
   
                 {
                   loading ? 
                   <button
                     className="btn primary-solid-btn border-radius"
                     style={{ width: "100%" }}
                     disabled = { true }
                   >
                     Loading ...
                   </button> :
                   <button
                   className="btn primary-solid-btn border-radius"
                   style={{ width: "100%" }}
                 >
                   Submit
                 </button>
                 }
              
               </Form>
         
           

        
          </div>
        </div>
      </Modal>




  </div>

  )
}

export default My_Challenges